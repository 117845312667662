import { Component, OnInit,ElementRef} from '@angular/core';
import { Router} from '@angular/router';
import { StorageService} from '@core/public/storage.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
   

  isLogin:boolean=false;
  constructor(
    private router:Router,
    private el: ElementRef,
    private  storageService:  StorageService,
  ) { }

  scrollToBottom(): void {
    try {
      this.el.nativeElement.querySelector('#idsContanct').scrollTop = this.el.nativeElement.querySelector('#idsContanct').scrollHeight;
    } catch (err) {}
  }
  

  ngOnInit() {
    // this.scrollToBottom();
    if(this. storageService.get('userToken')){
      this.isLogin=true;
    }else{
      this.isLogin=false;
    }
   
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.scrollToBottom();
    if(this. storageService.get('userToken')){
      this.isLogin=true;
    }else{
      this.isLogin=false;
    }
  }

}
