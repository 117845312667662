import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ThemeService } from './../../core/httpservice/theme.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '@env/environment';

import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-luxin-theme_m',
  templateUrl: './luxin-theme_m.component.html',
  styleUrls: ['./luxin-theme_m.component.css']
})
export class LuxinTheme_mComponent implements OnInit {

  constructor(

    private router: Router,
    private route: ActivatedRoute,

	    private themeService: ThemeService,
    private storageService: StorageService,
    private msg: NzMessageService,
  ) { 
  this.nativeWindow = themeService.getNativeWindow();
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          this.year = this.route.snapshot.queryParams['year'];
		  this.id = this.route.snapshot.queryParams['id'];
		  this.mouse = this.route.snapshot.queryParams['mouse'];
		  this.type = this.route.snapshot.queryParams['type'];
          // this.itemColorCache=new Object();
          // this.isNewFilter=true;
          // this.filterGlassesList();
         // this.currentUrl = this.router.url.split('?')[0].split('/').pop();
          //this.getBannerUrl();
        }
      });
	  this.router.routeReuseStrategy.shouldReuseRoute = () => {
		  return false;
	  };
  }
   isLogin: boolean = true;
  a1: any;a2: any;
  title: any;
  mouse: any;
  url: any;
  type: any;
  swf: any;id: string;
  nativeWindow: any
 year: string;
 oneyear: string;
 themList: any[]=[];
 discuss:any[]=[];
 zan:any;  xt2:any;
  fileUrl = environment.FILE_URL;
  
  userip:Array<any> =[];
  getThemeList() {
	  if(!this.year){ this.year="";};
	  this.swf="2";
	  if(!this.id){ 
	  this.swf="1"; 
	  //this.a1="nonono";
    let data = {
      user_token: this.storageService.get('userToken'),
	  oneyear:this.year
      //folder_id: this.detailId
    };
    this.themeService.getThemeList(data).subscribe((res) => {
      if(res.code==0){
       
		this.themList = res.data.list;
		//this.A1=JSON.stringify(res.data);
		//this.A2=JSON.stringify(res.data.list);
		 this.year=this.themList[0]['mouse'];
      }else{
        this.themList=[];
		
      }
     
    })
	}else{
		
		
		 if(this.isLogin){
			 
		this.xt2=this.storageService.get('userToken'); 
		 }else{
			this.xt2='1';
		 }
		
		
		let data = {
        user_token: this.xt2,
	    id:this.id,
		type:this.type
        //folder_id: this.detailId
        };
		this.zan="NOZAN";
		
        this.themeService.getThemeOne(data).subscribe((res) => {
        if(res.code==0){
       
		this.themList = res.data.list;
		//this.a1=this.fileUrl;
		if(this.type>2){
		this.url=this.themList[0]['produrl'];	
		}else{
		this.url=this.themList[0]['url'];
		}
		//alert(this.url);
		this.discuss = res.data.discuss;
		
		 this.year=this.themList[0]['mouse'];
		 this.title=this.themList[0]['title'];
		 if(this.isLogin){
		 this.zan = res.data.zan;}else{
			 this.zan =1;
		 }
		 //this.a1=JSON.stringify(this.discuss);
	     }else{
			// this.a1=JSON.stringify(res)
			 }
		})
	}
  }
  
  onScroll(): void {
    console.log('滚动了');
	
   // if (this.isLoading) return;
   // this.isLoading = true;
   // if (this.list.length == this.totalAmount) {
    //  this.hasMore = false;
   //   this.isLoading = false;
   //   clearTimeout(this.timmer);
   //   console.log('到底了');
   //   return;
  //  } else {
   //   this.currentPage++;
   //   console.log('滚动了页数' + this.currentPage);
    //  this.timmer = setTimeout(() => {
        //  请求分页
   //     this.getGlassesList();
  //      this.isLoading = false;
    //  }, 20);
   // } 
  }
  goToTheme(id, year,type): void {
    if (this.isLogin) {
      let postId = id;
     // let groupColor = this.list.find(item => item['id'] == id)['colors'];
     // groupColor.forEach(item => {
     //   if (this.itemColorCache[item.id]['checked']) {
      //    postId = item.id;
     //   }
    //  })
      //  this.router.navigate(['/glasses-detail'],{ queryParams: { id: postId,glassesType:type }})

      this.nativeWindow.open(`/#/luxin-theme?id=${postId}&year=${year}&type=${type}`,'_self');
    } else {
      this.msg.warning('If you want to see more, please sign in first !');
    }


  }
  saveThemeinfo(item) {
	  var xt21= document.getElementById('ipkey2') as HTMLInputElement;
	  
	  
	   if(this.isLogin){
		  this.a1= this.storageService.get('userToken'); 
		  this.xt2='1';
	  }else{
		  this.a1='1';
	  if(xt21.value == "") {
		  this.xt2='1';
		  }else{
			  this.xt2 = JSON.parse(xt21.value).ip ;
			   //alert(this.xt2);
		  }
	  }
	  
    let params = {
      user_token: this.a1,
      id: this.id,
	  type: this.type,
      comment: item,
	  ip:this.xt2
    }
    this.themeService.addMyThemeInfo(params).subscribe(
      res => {
        if (res.code == 0) {
		//this.a1=JSON.stringify(res.data);
          this.msg.success('Success !');
		  item='';
		this.discuss = res.data.discuss;
        } else {
          this.msg.error('Fail !')
        }

      })

  }
  
  savethumb() {
	 
	  var xt21= document.getElementById('ipkey2') as HTMLInputElement;
	  if(this.isLogin){
		  this.a1= this.storageService.get('userToken'); 
		  this.xt2='1';
	  }else{
		  this.a1='1';
	  if(xt21.value == "") {
		  this.xt2='1';
		  }else{
			  this.xt2 = JSON.parse(xt21.value).ip ;
			   //alert(this.xt2);
		  }
	  }
	  
    let params = {
		//这里需要把默认客户改成IP地址
      user_token: this.a1,
      id: this.id,
	   type: this.type,
	  zan:this.zan,
     ip:this.xt2
    }
    this.themeService.addThemeInfoThumbUp(params).subscribe(
      res => {
        if (res.code == 0) {
			if(this.zan>0){this.zan=0}else{this.zan=1}
		
         // this.msg.success('Success !')
		  
		
        } else {
          this.msg.error('Fail !')
        }

      })

  }
 display1(di) {
	  //this.msg.success('Success !');
	  var onediv=document.getElementById(di);
	  onediv.style.display="block";
	  
  }
  notdisplay(di) {
	  //this.msg.success('noSuccess !');
	  var onediv=document.getElementById(di);
	  onediv.style.display="none";
	  
  }
  xx(){
	  //{'background-image':'linear-gradient(to right,#'+color1+',#'+color2+')'}
  var xxc="{'width':'20px'}" 
	  return xxc;
  }
  
  
  getExternalIp() {
      const  xhr = new XMLHttpRequest();
        
        // 发送GET请求到公共的IP查询接口
        xhr.open('GET', 'https://api.ipify.org?format=json',true);
        
       xhr.onreadystatechange = function () {
            if (xhr.status === 200) {
				if(xhr.responseText != ""){
					
				//	alert(xhr.responseText);
				(document.getElementById('ipkey2') as HTMLInputElement).value=xhr.responseText;
				}	
            } else {
               // reject(new Error(`Error ${xhr.status}: ${xhr.statusText}`));
            }
        };
        
        xhr.send();
   
}

  
  
  ngOnInit() {
	 
	  //this.getThemeList();
	  if (this.storageService.get('userToken')) {
      this.isLogin = true;
	  } else {
      
      this.isLogin = false;
    }
	this.getExternalIp() ;
	this.getThemeList();
  }
   
}

