import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import {LayoutModule} from './../layout/layout.module';
import { RouteRoutingModule } from './routes-routing.module';
import {HomeComponent} from './home/home.component';
import {Home_mComponent} from './home/home_m.component';
import { LuxinThemeComponent } from './luxin-theme/luxin-theme.component';
import { LuxinTheme_mComponent } from './luxin-theme/luxin-theme_m.component';
import { EyeGlassesComponent } from './eye-glasses/eye-glasses.component';
import { GlassesDetailComponent } from './glasses-detail/glasses-detail.component';
import { AddMyOrderModalComponent } from './components/add-my-order-modal/add-my-order-modal.component';
import { MyOrderComponent } from './my-center/my-order/my-order.component';
import { UserCenterHeaderComponent } from './components/user-center-header/user-center-header.component';
import { MyFavoritesComponent } from './my-center/my-favorites/my-favorites.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { Userwho } from './sign-up/user-who.component';
import { ForgotPasswordModalComponent } from './components/forgot-password-modal/forgot-password-modal.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { UpdateBrandFolderModalComponent } from './components/update-brand-folder-modal/update-brand-folder-modal.component';
import { EditMyBrandDetailComponent } from './edit-my-brand-detail/edit-my-brand-detail.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { MyThemesComponent } from './my-center/my-themes/my-themes.component';

import { SunGlassesComponent } from './sun-glasses/sun-glasses.component';
import { GlassesListComponent } from './components/glasses-list/glasses-list.component';
import { MyCenterComponent } from './my-center/my-center.component';
import { SubmitBrandFolderModalComponent } from './components/submit-brand-folder-modal/submit-brand-folder-modal.component';


const COMPONENTS = [
    HomeComponent,
	Home_mComponent,
    LuxinThemeComponent,
	LuxinTheme_mComponent,
    EyeGlassesComponent,
    GlassesDetailComponent,
    AddMyOrderModalComponent,
    MyOrderComponent,
    UserCenterHeaderComponent,
    MyFavoritesComponent,
    SignInComponent,
    SignUpComponent,
	Userwho,
    ForgotPasswordModalComponent,
    EditProfileComponent,
    ChangePasswordModalComponent, 
    UpdateBrandFolderModalComponent,
    EditMyBrandDetailComponent,
    SuccessModalComponent,
    MyThemesComponent,

    SunGlassesComponent,
    GlassesListComponent,
    MyCenterComponent,
    SubmitBrandFolderModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouteRoutingModule,
    LayoutModule
  ],
  declarations: [...COMPONENTS],
})
export class RoutesModule { }