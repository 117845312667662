import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd';
import{ UserService} from '@core/httpservice/user.service';
import {UserInfoService} from '@core/public/user-info.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-user-center-header',
  templateUrl: './user-center-header.component.html',
  styleUrls: ['./user-center-header.component.css']
})
export class UserCenterHeaderComponent implements OnInit {
  fileUrl=environment.FILE_URL;
  avatarHeader=environment.AVATAR_URl;

   // 判断当前路由
 currentUrl: string = this.router.url.split('?')[0].split('/').pop();

 constructor(
   private router: Router,
   private msg:NzMessageService,
   private storageSrv:StorageService,
   private userService: UserService, 
   private userInfoService:UserInfoService,
 ) { 
   this.router.events
     .subscribe((event: NavigationEnd) => {
       if(event instanceof NavigationEnd){
         this.currentUrl = this.router.url.split('?')[0].split('/').pop();
       }
     });
 }
 userInfo:any={};
 //获取用户信息
 getUserInfo() {
   let data = {
     user_token: this.storageSrv.get('userToken')
   }
   this.userService.getUserInfo(data).subscribe(res => {
     if(res.code==0){
       this.userInfo = res.data;  
     }     
   })
 }

  ngOnInit(): void {
    this.userInfoService.updataUserInfo();
    this.userInfoService.getUserInfo().subscribe(
      res=>{
        this.userInfo=res;
      }
    )
  }

}
