import { Component, OnInit, Input } from '@angular/core';
import { GlassesService } from '@core/httpservice/glasses.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { GlassesInfoService } from '@core/public/glasses-info.service';
import { StorageService } from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-glasses-list',
  templateUrl: './glasses-list.component.html',
  styleUrls: ['./glasses-list.component.css']
})
export class GlassesListComponent implements OnInit {

  // 判断当前路由
  currentUrl: string = this.router.url.split('?')[0].split('/').pop();

  constructor(
    private glassesService: GlassesService,
    private router: Router,
    private route: ActivatedRoute,
    private glassesInfoSrv: GlassesInfoService,
    private storageService: StorageService,
    private msgSrv: NzMessageService,
  ) {
    this.nativeWindow = glassesService.getNativeWindow();
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          this.gender = this.route.snapshot.queryParams['gender'];
          // this.itemColorCache=new Object();
          // this.isNewFilter=true;
          // this.filterGlassesList();
          this.currentUrl = this.router.url.split('?')[0].split('/').pop();
          this.getBannerUrl();
        }
      });
  }
  nativeWindow: any
  @Input() glassesType: string;
  @Input() gender: string;
  fileUrl = environment.FILE_URL;
  sort: any;
  product: any;
  isLogin: boolean = true;
  bannerUrl:string=''

  getBannerUrl(): void {
   
    let str = '';
    switch (this.currentUrl) {
      case 'eye-glasses':
        switch (this.gender) {
          case 'Men':
            str = 'MEN_glasses.jpg';
            break;
          case 'Women':
            str = 'women_glasses.jpg';
            break;
          case 'Kids':
            str ='kid_glasses.jpg';
            break;
          default:
            str = 'MEN_glasses.jpg';
            break;
        }
        break;
      case 'sun-glasses':
        switch (this.gender) {
          case 'Men':
            str = 'men_sun.jpg';
            break;
          case 'Women':
            str = 'WOMEN_sunglasses.jpg';
            break;
          default:
            str = 'men_sun.jpg';
            break;
        }
        break;
    }
    this.bannerUrl=`/assets/images/${str}`;

  }


  // region 获取属性
  getGlassesAttribute(): void {
    let data = {
      glasses_type: this.glassesType,
      gender: this.gender
    }
    for (let key in data) {
      if (!data[key]) {
        delete data[key]
      }
    }
    this.glassesService.getGlassesAttribute(data).subscribe(
      res => {
        this.colorList = [...res.data.color.map(item => { item['checked'] = false; return item })];
        this.shapeList = [...res.data.shape.map(item => { item['checked'] = false; return item })];;
        this.materialList = [...res.data.material.map(item => { item['checked'] = false; return item })];
      });
  }
  // endregion
  // region 颜色
  color: any[] = [];
  colorList: any[] = [];
  colorChange(event: any[]): void {
    this.color = event;
    this.filterGlassesList();
  }
  // endregion
  // region 形状
  shape: any[] = [];
  shapeList = [];
  shapeChange(event: any[]): void {
    this.shape = event;
    this.filterGlassesList();
  }
  // endregion
  // regin 材料
  material: any[] = [];
  materialList = [];
  materialChange(event: any[]): void {
    this.material = event;
    this.filterGlassesList();
  }

  // endregion


  //**选择筛选项目 */
  currentScreenType: any = 'isNew';
  selectedType(type): void {
    this.currentScreenType = type;
    this.isShowAllColor = false;
    if (type == "isNew") {
      this.isNewFilter = true;
      this.filterGlassesList();

    } else {
      this.isNewFilter = false;
      if (this.currentScreenType == 'reset') {
        this.resetFilter();
      } else {
        this.filterGlassesList();
      }

    }

  }



  /**重置筛选按钮 */
  resetFilter(): void {
    this.colorList.map(item => { item['checked'] = false; return item });
    this.materialList.map(item => { item['checked'] = false; return item });
    this.shapeList.map(item => { item['checked'] = false; return item });
    this.color = [];
    this.shape = [];
    this.material = [];
    this.filterGlassesList();
    this.getGlassesAttribute();
  }

  // region 颜色隐藏
  isShowAllColor: boolean = false;
  showMoreColorChange(): void {
    this.isShowAllColor = !this.isShowAllColor;
  }
  // endregion 



  // region 获取眼镜列表
  totalAmount: number = 0;
  currentPage: number = 1;
  pageSize: number = 18;
  cacheList: any[] = [];
  list: any[] = [];
  isNewFilter: boolean = true;
  getGlassesList(): void {
	  if(this.product){
		this.product=this.product.toUpperCase();  
	  }

    if (this.isNewFilter) {
      let data = {
        page: this.currentPage,
        sort: this.sort ? this.sort : 'DESC',
		product: this.product ? this.product : '',
        glassesType: this.glassesType ? this.glassesType : 'eyeglasses',
        gender: this.gender,
        user_token: this.storageService.get('userToken'),
      }
      for (let key in data) {
        if (!data[key]) {
          delete data[key]
        }
      }
      this.glassesService.getNewGlasses(data).subscribe(
        res => {
          if (res.code == 0) {
            if (!this.isLogin && this.currentScreenType == 'isNew') {
              this.currentPage = 1;
              this.list.length = 0;
              this.list = [];
              this.totalAmount = 0;

            } else {
              this.list = [...this.list, ...res.data['list']];
              this.totalAmount = res.data['total'];
              this.updateItemColorCache();
            }

          } else {
            this.currentPage = 1;
            this.list.length = 0;
            this.list = [];
            this.totalAmount = 0;
          }
        },
      )

    } else {
      let data = {
        glassesType: this.glassesType ? this.glassesType : 'eyeglasses',
        gender: this.gender ? this.gender : 'Men',
        page: this.currentPage,
        sort: this.sort ? this.sort : 'DESC',
		product: this.product ? this.product : '',
        color: this.color.length > 0 ? this.color.join(',') : '',
        material: this.material.length > 0 ? this.material.join(',') : '',
        shape: this.shape.length > 0 ? this.shape.join(',') : '',
        user_token: this.storageService.get('userToken'),
      }
      for (let key in data) {
        if (!data[key]) {
          delete data[key]
        }
      }
	  
	  
      this.glassesService.glassesList(data).subscribe(
        res => {
          if (res.code == 0) {
            if (!this.isLogin && this.currentScreenType == 'isNew') {
              this.currentPage = 1;
              this.list.length = 0;
              this.list = [];
              this.totalAmount = 0;

            } else {
              this.list = [...this.list, ...res.data['list']];
              this.totalAmount = res.data['total'];
              this.updateItemColorCache();
            }

          } else {
            this.currentPage = 1;
            this.list.length = 0;
            this.list = [];
            this.totalAmount = 0;
          }
        },

      )

    }

  }
  // 滚动加载
  hasMore = true;
  timmer: any;
  isLoading: boolean = false;

  onScroll(): void {
    console.log('滚动了');
    if (this.isLoading) return;
    this.isLoading = true;
    if (this.list.length == this.totalAmount) {
      this.hasMore = false;
      this.isLoading = false;
      clearTimeout(this.timmer);
      console.log('到底了');
      return;
    } else {
      this.currentPage++;
      console.log('滚动了页数' + this.currentPage);
      this.timmer = setTimeout(() => {
        //  请求分页
        this.getGlassesList();
        this.isLoading = false;
      }, 20);
    }
  }
  /**筛选加载 */
  filterGlassesList(): void {
    this.currentPage = 1;
    this.list.length = 0;
    this.list = [];
    this.totalAmount = 0;
    this.getGlassesList();
  }
  // endregion

  //region  选择眼镜id
  itemColorCache = {};
  updateItemColorCache(): void {
    if (this.list.length > 0) {
      this.list.forEach(item => {
        item['colors'].forEach(color => {
          let checked = false;
          if (item.id == color.id) {
            checked = true;
          }
          if (!this.itemColorCache[color.id]) {
            this.itemColorCache[color.id] = {
              checked: checked,
              ...color
            };
          }
        })
      })
    }
  }
  // endregin

  // 跳转眼镜详情
  // [routerLink]="['/glasses-detail']" [queryParams]="{id:item.id, glassesType:item.glasses_type}"
  goToDetail(id, type): void {
    if (this.isLogin) {
      let postId = id;
      let groupColor = this.list.find(item => item['id'] == id)['colors'];
      groupColor.forEach(item => {
        if (this.itemColorCache[item.id]['checked']) {
          postId = item.id;
        }
      })
      //  this.router.navigate(['/glasses-detail'],{ queryParams: { id: postId,glassesType:type }})

      this.nativeWindow.open(`/#/glasses-detail?id=${postId}&glassesType=${type}`);
    } else {
      this.msgSrv.warning('If you want to see more, please sign in first !')
    }


  }
  // 颜色选择控制
  selectColorChange(groupColorId, colorId): void {
    let groupColor = this.list.find(item => item['id'] == groupColorId)['colors'];
    groupColor.forEach(item => {
      this.itemColorCache[item.id]['checked'] = false;
    })
    this.itemColorCache[colorId]['checked'] = true;
  }


  ngOnInit() {
    this.getBannerUrl();
    // 监听选择
    this.glassesInfoSrv.getGlassesInfo().subscribe(
      res => {
        // console.log('监听了')
        this.gender = res.gender;
        this.glassesType = res.glassesType;
        this.itemColorCache = new Object();
        if (this.storageService.get('userToken')) {
          this.isLogin = true;
          //this.currentScreenType='isNew';
          //this.isNewFilter = true;
           this.currentScreenType = 'reset';
           this.isNewFilter = false;
        } else {
         // this.isNewFilter = true;
         // this.currentScreenType='isNew';
           this.currentScreenType = 'reset';
           this.isNewFilter = false;
          this.isLogin = false;
        }
        this.filterGlassesList();
        this.getGlassesAttribute();
      }
    )
    this.getGlassesAttribute();
    this.itemColorCache = new Object();


    if (this.storageService.get('userToken')) {
      this.isLogin = true;
      //this.currentScreenType='isNew';
      //this.isNewFilter = true;
       this.currentScreenType = 'reset';
       this.isNewFilter = false;
    } else {
       this.currentScreenType = 'reset';
       this.isNewFilter = false;
      //this.isNewFilter = true;
      //this.currentScreenType='isNew';
      this.isLogin = false;
    }
    this.filterGlassesList();
  }



}
