import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService} from '@core/httpservice/order.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as $ from 'jquery';
import { HttpHeaderService} from '@core/public/http-header.service';
import {environment} from '@env/environment';
// import { saveAs } from 'file-saver';

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})
export class MyOrderComponent implements OnInit {

  constructor(
    private orderService: OrderService, 
    private storageService: StorageService,
    private msg:NzMessageService,
    private headerSrv:HttpHeaderService,
    ) { }
    fileUrl=environment.FILE_URL;
  ngOnInit(): void {
    this.getMyOrder()
  }

  // 修改订单弹窗
  curentAction:string="add";
  @ViewChild('updateBrandFolderModal') updateBrandFolderModal;
  updateBrandBtn(action):void{
    this.curentAction=action;
    this.updateBrandFolderModal.isVisible=true;
  }
  handleOuter(event):void{
    if(event){
      this.getMyOrder();
    }
  }

  orderList: []=[]

  // 我的订单
  getMyOrder () {
    let data  = {
      user_token: this.storageService.get('userToken')
    }
    this.orderService.getOrderList(data).subscribe((res) => {
      if(res.code==0){
        this.orderList = res.data
      }else{
        this.orderList=[];
      }
     
    })
  }

  // region 订单导出
  exportOrder(brandFolderId,isCan):void{
    if(isCan){
      let data={
        user_token:this.storageService.get('userToken'),
        brandFolderId:brandFolderId
      }
      let url=`${environment.SERVER_URL}Execl/export${this.headerSrv.toQueryString(data)}`;
      // console.log(url)
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.setAttribute('href', url);
      this.msg.success('Success !')
      // a.setAttribute('download',);
      // a.click();
      a.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      ); //兼容火狐
      document.body.removeChild(a);
    }else{
      this.msg.warning('This brand folder is empty !')
    }
    

  }
 

  // endregion



  // 提交订单弹窗
  isLoading:boolean=false;
  @ViewChild('submitBrandFolderModal') submitBrandFolderModal;
  submitOrder():void{
    this.submitBrandFolderModal.isVisible=true;
    // let data={
    //   user_token: this.storageService.get('userToken')
    // }
    // this.isLoading=true;
    // this.orderService.submitOrder(data).subscribe(
    //   (res) => {
    //     this.isLoading=false;
    //   if(res.code==0){
    //    this.msg.success('Success !');
    //    this.getMyOrder();
    //   }else{
    //     this.msg.success('Fail !')
    //   }
     
    // },
    // ()=>{this.isLoading=false},
    // ()=>{this.isLoading=false}
    // )

  }
}
