 import { Component, OnInit,ElementRef,HostListener} from '@angular/core';

import { StorageService} from '@core/public/storage.service';
import { ThemeService } from './../../core/httpservice/theme.service';
import {GlassesInfoService} from '@core/public/glasses-info.service';
import { Router, NavigationEnd, } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home_m.component.html',
  styleUrls: ['./home_m.component.css']
})
export class Home_mComponent implements OnInit {
    

  isLogin:boolean=false;
  constructor(
    private router:Router,
	 private themeService: ThemeService,
    private el: ElementRef,
    private  storageService:  StorageService,
  ) {this.nativeWindow = themeService.getNativeWindow(); }
nativeWindow: any
  scrollToBottom(): void {
    try {
      this.el.nativeElement.querySelector('#idsContanct').scrollTop = this.el.nativeElement.querySelector('#idsContanct').scrollHeight;
    } catch (err) {}
  }
  

  ngOnInit() {
    // this.scrollToBottom();
    if(this. storageService.get('userToken')){
      this.isLogin=true;
    }else{
      this.isLogin=false;
    }
	 var onediv5= this.storageService.get("kaix");
	 
	if(onediv5=="1"){
		
		var onediv=document.getElementById("kai");
		 onediv.style.display="none";
	}
	
    this.onload1();
  }
  tuyincang(): void {
	   this.storageService.set("kaix","1");
	  
	  var onediv=document.getElementById("kai");
	  const tu=document.getElementById("yaotu");
	  tu.setAttribute('src','/assets/images/yaoqing2.jpeg');
	  setTimeout(function(){
		  onediv.style.display="none";
		  
	  },3000);
	  
	  
	 
  }
  
  goToTheme(id, year,type): void {
    
      let postId = id;
     // let groupColor = this.list.find(item => item['id'] == id)['colors'];
     // groupColor.forEach(item => {
     //   if (this.itemColorCache[item.id]['checked']) {
      //    postId = item.id;
     //   }
    //  })
      //  this.router.navigate(['/glasses-detail'],{ queryParams: { id: postId,glassesType:type }})

      this.nativeWindow.open(`/#/luxin-theme_m?id=${postId}&year=${year}&type=${type}`,'_self');
    
  }
  
 // @HostListener("window:load",["$event"])
 onload1(){
     // 1.获取元素
   // var focus = document.querySelector('.focus') as NodeListof<HTMLElement>;
    var ul = document.getElementById('move1');//focus.children[0];
    var ol = document.getElementById('move2'); // focus.children[1];
    // 移动的每张图片的宽度，就是focus宽度
    // 获得focus的宽度
    var w =  1600;//focus.offsetWidth;
    // 2.利用定时器自动轮播图片
    // 声明一个变量index来控制ul每次移动
    var index = 0;
    var timer = setInterval(function () {
        index++;
        var translatex = -index * w;//因为是往左走，所以是负值
        ul.style.transition = 'all .3s';//添加过渡，让图片慢点自动滚动
        ul.style.transform = 'translateX(' + translatex + 'px)';//每隔2S，往左滚动一张图片
    }, 3000);
    // 等着过渡完成之后，再去判断 监听过渡完成的事件 transitionend 
    ul.addEventListener('transitionend', function () {
        // 无缝滚动
        // 第一个focus1的index是0，最后一个focus1的index是3
        // 当index变为3或更大的时候，跳转回index为0
        if (index >= 5) {
            index = 0;
            // 去掉过渡，直接跳转
            ul.style.transition = 'none';
            // 用最新的index*宽度，继续滚动图片
            var translatex = -index * w;
            ul.style.transform = 'translateX(' + translatex + 'px)';
        } else if (index < 0) {
            // 当index<0的时候，跳转回index为2
            index = 4;
            // 去掉过渡，直接跳转
            ul.style.transition = 'none';
            // 用最新的index*宽度，继续滚动图片
            var translatex = -index * w;
            ul.style.transform = 'translateX(' + translatex + 'px)';
        }
        // 3.小圆点跟随变化效果
        // 移除（classList.remove）ol中li中有current类名的li
        ol.querySelector('li.current').classList.remove('current');
        // 给当前index的li添加（classList.add）类名current
        ol.children[index].classList.add('current');
 
    });
    // 4.手指滑动轮播图
    // 本质上是让ul跟随手指移动
    // 触摸元素（touchstart）获得手指初始坐标
    // 此处是左右滑动，所以只需要X坐标
    var startX = 0;
    var moveX = 0;//之后会使用到该移动距离，所以要是全局变量
    var flag = false;//声明一个全局变量flag
    ul.addEventListener('touchstart', function (e) {
        startX = e.targetTouches[0].pageX;
        // 手指滑动轮播图的时候，要停止轮播图的自动滚动
        // 当手指按下时候，就停止图片自动滚动
        clearInterval(timer);
    });
 
    // 手指滑动（touchmove）获得手指移动距离
    ul.addEventListener('touchmove', function (e) {
        // 手指移动距离=手指滑动时的手指X坐标-手指初始X坐标
        moveX = e.targetTouches[0].pageX - startX;
        // 盒子最终移动距离=盒子之前的移动距离+手指移动的距离
        var translatex = -index * w + moveX;
        // 手指移动的时候不需要过渡效果，所以去掉过渡，直接跳转
        ul.style.transition = 'none';
        ul.style.transform = 'translateX(' + translatex + 'px)';
        flag = true;//如果用户手指移动过再去判断，不然不判断
        e.preventDefault();//阻止滚动ul的时候，让屏幕滚动的行为
    });
    // 手指移开（touchend）根据移动距离判断是 回弹 还是 播放上一张/下一张
    ul.addEventListener('touchend', function (e) {
        if (flag) {//如果用户手指移动过再去判断，不然不判断
            // （1）如果移动距离大于50px,则播放上一张/下一张
            if (Math.abs(moveX) > 50) {
                // 如果是右滑，则播放下一张，moveX是正值
                if (moveX > 0) {
                    index--;
                } else {
                    // 如果是左滑，则播放上一张，moveX是负值
                    index++;
                }
                // 用最新的index*宽度，继续滚动图片
                var translatex = -index * w;
                // 图片滚动时要有过渡效果
                ul.style.transition = 'all .3s';
                ul.style.transform = 'translateX(' + translatex + 'px)';
            } else {
                // (2)如果移动距离小于50px,则回弹
                // 用最新的index*宽度，继续滚动图片
                var translatex = -index * w;
                // 图片滚动时要有过渡效果
                ul.style.transition = 'all .1s';
                ul.style.transform = 'translateX(' + translatex + 'px)';
            }
 
        }
        // 手指离开的时候就重新开启定时器
        clearInterval(timer);//先清除定时器，保证页面中只有一个定时器
        //再开启定时器
        timer = setInterval(function () {
            index++;
            var translatex = -index * w;
            ul.style.transition = 'all .3s';
            ul.style.transform = 'translateX(' + translatex + 'px)';
        }, 3000);
    });
     
}
  
  
  movepage():void {
	 
	  var startX;
	  var startY;
	  var deltaX;
	  var  deltaY;
	  var move1 = document.getElementById('move1');
  var container = document.getElementById('container11');
  var images1 = container.querySelectorAll('img');
   var curindex =0;
  
  container.addEventListener('touchstart',function(evernt){
	  var touch = evernt.changedTouches;
	   startX = touch[0].pageX;
	   startY = touch[0].pageY;
  });
  
	  container.addEventListener('touchmove',function(evernt){
		  move1.style.animationName   = '';
		  var touch = evernt.changedTouches;
		   deltaX = touch[0].pageX-startX;
		   
	  if (deltaX >0 ){
		 move1.style.marginLeft= String( Number(move1.style.marginLeft) + deltaX);
	  } else {
		  
		   move1.style.marginLeft= String( Number(move1.style.marginLeft) - deltaX);
	  }
  });

 
 container.addEventListener('touchend', function(event) {
	 var touch = event.changedTouches;
	 var endX = touch[0].pageX;
	 var deltaX1 = endX - startX;
	 
	 if(deltaX1 > 0) {
		 container.classList.add('swipe-right');
		 curindex--;
		 if (curindex <0) {
			 curindex = images1.length -1;
		 }
	 } else if (deltaX1 <0) {
		 container.classList.add('swipe-left');
		 curindex++;
		 if (curindex >= images1.length) {
			 curindex = 0;
		 }
	 }
	 
	// setTimeout(function() {
	//	 container.classList.remove('swipe-left','swipe-right');
	// },3000);
 });
  }
  
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.scrollToBottom();
    if(this. storageService.get('userToken')){
      this.isLogin=true;
    }else{
      this.isLogin=false;
    }
	this.movepage();
  }

}
