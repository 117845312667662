import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import{ PassportService} from './../../core/httpservice/passport.service';
import { NzMessageService } from 'ng-zorro-antd';
import {LoginService} from './../../core/public/login.service';
import { Router } from '@angular/router';
import { StorageService} from './../../core/public/storage.service';
import {UserInfoService} from '@core/public/user-info.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
 
})
export class SignInComponent implements OnInit {

  validateForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private passportSrv:PassportService,
    private msg:NzMessageService,
    private loginService:LoginService,
    private router:Router,
    private storageSrv:StorageService,
    private userInfoService:UserInfoService 
    ) {
      this.validateForm = this.fb.group({
        username: [null, [Validators.required]],
        password: [null, [Validators.required]],
        remember: [false,]
      });
    }

  // region 登陆
  isSubmitLoading:boolean=false;
  submitForm(): void {
	  
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return ;
	
    let data={
      username:this.validateForm.controls.username.value,
      password:this.validateForm.controls.password.value,
    }
    this.isSubmitLoading=false;
    this.passportSrv.login(data).subscribe(
      res=>{
        this.isSubmitLoading=false;
        if(res.code==0){
          this.loginService.sendLoginInfo(true);
          this.storageSrv.set('userToken',res.data.user_token)
          
          this.msg.success('Landing successfully！');
          // 记住密码
          this.storageSrv.set('isRememberPwd',this.validateForm.controls.remember.value);
          if(this.validateForm.controls.remember.value){
            this.storageSrv.set('account',this.validateForm.value)
          }else{
            this.storageSrv.remove('account')
          }
        //  跳转首页
          setTimeout(() => {
            this.router.navigate(['/home']);
            this.userInfoService.updataUserInfo();
          }, 200)
        }else{
          this.msg.error('Wrong user name or password!')
        }
      },
      ()=>{ this.isSubmitLoading=false;},
      ()=>{ this.isSubmitLoading=false;}
    )
  }

  // endregion

  // 忘记密码弹窗
  @ViewChild('forgotpwdModal') forgotpwdModal;
  isFogotVisible:boolean;
  forgotPwdBtn():void{
    this.isFogotVisible=true;
    this.forgotpwdModal.isVisible=true;
    this.forgotpwdModal.isShowReset=false;
  }

  

 

  

  ngOnInit(): void {
    this.loginService.sendLoginInfo(false);
   
    if(this.storageSrv.get('isRememberPwd')&&this.storageSrv.get('account')){
      this.validateForm.reset(this.storageSrv.get('account'))
    }else{
      this.validateForm.reset()
    }
  }

}
