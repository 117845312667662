import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import{ UserService} from './../../core/httpservice/user.service';
import { NzMessageService } from 'ng-zorro-antd';
import {LoginService} from './../../core/public/login.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
  
@Component({
  selector: 'app-user-who',
  templateUrl: './user-who.component.html',
  styleUrls: ['./user-who.component.css']
})
export class Userwho implements OnInit {
	validateForm: FormGroup;
  constructor(
    private fb: FormBuilder,
	private http: HttpClient,
    private passportSrv:UserService,
    private msg:NzMessageService,
    private loginService:LoginService,
    private router:Router
    ) {
		
		
      this.validateForm = this.fb.group({
        email: [null, [Validators.required]]
        
      });
    
	}


  isLoading:boolean=false;

  submitForm(): void {
	  
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
	  
    }
    if (this.validateForm.controls.email.value==null) return ;
	
	var xt= document.getElementById('ipkey') as HTMLInputElement;
	   
	//alert(xt.value);
  if(xt.value == "") {xt.value='1';}else{xt=JSON.parse(xt.value).ip;}
	 let data={
      email:this.validateForm.controls.email.value,
      ip:xt,
    }
    this.isLoading=true;
    this.passportSrv.sendwho(data).subscribe(
      res=>{
		  
        this.isLoading=false;
        if(res.code==0){
          this.loginService.sendLoginInfo(false);
          this.msg.success('login was successful！');
          setTimeout(() => {
            this.router.navigate(['/sign-in']);
          }, 200)
        }else{
          this.msg.error('login has failed!')
        }
      },
      ()=>{ this.isLoading=false;},
      ()=>{ this.isLoading=false;}
    )

  }
 

  
 getExternalIp() {
      const  xhr = new XMLHttpRequest();
        
        // 发送GET请求到公共的IP查询接口
        xhr.open('GET', 'https://api.ipify.org?format=json',true);
        
       xhr.onreadystatechange = function () {
            if (xhr.status === 200) {
				if(xhr.responseText != ""){
					
				//	alert(xhr.responseText);
				(document.getElementById('ipkey') as HTMLInputElement).value=xhr.responseText;
				}	
            } else {
               // reject(new Error(`Error ${xhr.status}: ${xhr.statusText}`));
            }
        };
        
        xhr.send();
   
}

 
 
 

  ngOnInit() {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      email: [null,[Validators.email, Validators.required]],
      password: [null, [Validators.required]],
    });
   
   

 this.getExternalIp() ;
  }

}