import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ UserService} from '@core/httpservice/user.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {UserInfoService} from '@core/public/user-info.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  styles: [
    `
      [nz-form] {
        max-width: 1000px;
      }
      ::ng-deep .ant-form-item-control input{
        border-radius:10px;
        color:#000;
        font-weight:500;
        font-size: 16px;
      }
      ::ng-deep .ant-form-item-label > label{
        color:#000;
        font-weight:500;
        font-size: 16px;
      }

      .phone-select {
        width: 70px;
      }

      .register-are {
        margin-bottom: 8px;
      }
      .avatar {
        width: 128px;
        height: 128px;
      }
      .upload-icon {
        font-size: 32px;
        color: #999;
      }
      .ant-upload-text {
        margin-top: 8px;
        color: #666;
      }
      ::ng-deep .ant-upload.ant-upload-select-picture-card{
        border-radius:50%
      }
      ::ng-deep .ant-form-item label{
        color: #000;
    font-weight: 500;
    font-size: 16px;

      }
    `
  ]
})
export class EditProfileComponent implements OnInit {
  validateForm: FormGroup;

  
 

  constructor(
    private fb: FormBuilder, 
    private userService: UserService, 
    private  storageService:  StorageService,
    private msg: NzMessageService,
    private userSrv:UserInfoService,
    ) {}

    avatarHeader=environment.AVATAR_URl;

    /**上传图片 */
    avatarUrl:string
    uplaod(event):void{
      this.userService.uploadImg(event.target.files).then(
        res=>{
          if(res.data){
            this.avatarUrl = res.data['filepath'];
          }
            
        }
      )
    }

  // 修改密码
  @ViewChild('changePwdModal') changePwdModal;
  changePwdBtn():void{
   this.changePwdModal.isVisible=true;
  }
  // endregion

  // 获取用户信息
  userInfo: any
  getUserInfo() {
    let data = {
      user_token: this.storageService.get('userToken')
    }
    this.userService.getUserInfo(data).subscribe(res => {
      if(res.code==0){
        this.userInfo = res.data;
        this.avatarUrl=res.data['head_portrait'];
        this.validateForm.reset({
          username:res.data['username'],
          email:res.data['email'],
          company:res.data['company'],
          gender:res.data['gender'],
          location:res.data['location'],
          office:res.data['office'],
          mobile:res.data['mobile'],
          title:res.data['title'],
        })
      }     
    })
  }
  // endregion

  // 提交修改信息
  isSubmitLoading:boolean=false;
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return ;
    let data={
      user_token:this.storageService.get('userToken'),
      head_portrait:this.avatarUrl
    }
    Object.assign(data,this.validateForm.value);
    this.isSubmitLoading=true;
    this.userService.updateUserInfo(data).subscribe(
      res=>{
        this.isSubmitLoading=false;
        if(res.code==0){
          this.msg.success('Success!');
          this.userSrv.sendUserInfo(data);
           // 获取用户信息
          this.getUserInfo()
        }else{
          this.msg.success('Fail!')
        }
      },
      ()=>{
        this.isSubmitLoading=false;
      },
      ()=>{
        this.isSubmitLoading=false;
      }
    )

  }

  ngOnInit(): void {
    // 获取用户信息
    this.getUserInfo()
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      username: [null, [Validators.required]],
      company: [null],
      gender: [null, [Validators.required]],
      location: [null],
      mobile: [null],
      office: [null],
      title: [null],
    });
  }

}