import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {

  constructor() { }
  isVisible:boolean=false;

  @Input() title:string;
  ngOnInit(): void {
  }

}
