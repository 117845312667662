import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs'
@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor() { }
  // Observable string sources
  private loginInfo = new Subject<string>();

  // Observable string streams
  loginInfo$ = this.loginInfo.asObservable();

  // Service message commands
  getLoginInfo(): Observable<any> {
    return this.loginInfo.asObservable();
  }
  // send
  sendLoginInfo(mission: any) {
    this.loginInfo.next(mission);
  }
}
