import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-center',
  templateUrl: './my-center.component.html',
})
export class MyCenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
