import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import{ UserService} from './../../../core/httpservice/user.service';
import { StorageService} from './../../../core/public/storage.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css'],
  styles: [
    `
      ::ng-deep .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ::ng-deep .vertical-center-modal .ant-modal {
        top: 0;
      }
      ::ng-deep .ant-modal-close{
        left:0;
      }
      ::ng-deep .ant-modal-content{
        border-radius: 20px;
      }
      ::ng-deep .ant-card-head{
        background-color: #1b1923;
      }
      [nz-form] {
        max-width: 600px;
      }

      .phone-select {
        width: 70px;
      }

      .register-are {
        margin-bottom: 8px;
      }
    `
  ]
})
export class ChangePasswordModalComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private userService: UserService, 
    private msg: NzMessageService,
    private storageService:  StorageService,
    ) {}

  validateForm: FormGroup;
  
  isVisible:boolean=false;

  loading:boolean=false;
  handleOk():void{
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return ;
    let data={
      user_token:this.storageService.get('userToken'),
      ...this.validateForm.value
    }
    this.loading=true
    this.userService.changepassword(data).subscribe(
      res=>{
        this.loading=false;
        if(res.code=='0'){
          this.msg.success('Success!');
          this.isVisible=false;
        }else{
          this.msg.error('Fail!')
        }
      },
      ()=>{ this.loading=false;},
      ()=>{ this.loading=false;},
    )

    
  }
  handleCancel():void{
 this.isVisible=false
  }
  modalOpen():void{
    this.validateForm.reset();
  }
  

  saveBtn():void{
    this.isVisible=false;
 
  }



  ngOnInit(): void {
    this.validateForm = this.fb.group({
      old_password: [null, [Validators.required]],
      new_password: [null, [Validators.required]],
    });
  }

}
