import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// region components
import { FilterByYearComponent } from './filter-by-year/filter-by-year.component';
// endregion

// region thrid module
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzGridModule } from 'ng-zorro-antd/grid';

// endregion


const THIRDMODULES=[
  NzSelectModule,
  NzButtonModule,
  NzCheckboxModule,
  NzCarouselModule,
  NzIconModule,
  NzModalModule,
  NzCardModule,
  NzListModule,
  NzMessageModule,
  NzFormModule,
  NzInputModule,
  NzNotificationModule,
  NzAnchorModule,
  NzEmptyModule,
  NzRadioModule,
  NzSpinModule,
  NzPopconfirmModule,
  NzDropDownModule,
  InfiniteScrollModule,
  NzBackTopModule,
  NzGridModule
]

const COMPONENTS=[
  FilterByYearComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ...THIRDMODULES,
  ],
  declarations: [...COMPONENTS],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ...THIRDMODULES,
    ...COMPONENTS
  ]
})
export class SharedModule { }
