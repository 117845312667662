import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BrandService} from '@core/httpservice/brand.service';
import { OrderService} from '@core/httpservice/order.service';
import { StorageService} from '@core/public/storage.service';
import { Router} from '@angular/router';
import {environment} from '@env/environment';
import { UserInfoService} from '@core/public/user-info.service';

@Component({
  selector: 'app-add-my-order-modal',
  templateUrl: './add-my-order-modal.component.html',
  styleUrls: ['./add-my-order-modal.component.css'],
  styles:[`
   ::ng-deep .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::ng-deep .vertical-center-modal .ant-modal {
    top: 0;
  }
  ::ng-deep .ant-modal-close{
    left:0;
  }
   ::ng-deep .ant-modal-content{
    border-radius: 20px;
  }
  ::ng-deep .ant-card-head{
    background-color: #1b1923;
  }
   ::ng-deep .card_box .ant-card-body{
    height: 327px;
    overflow: auto;
}
   
  `]
 

})
export class AddMyOrderModalComponent implements OnInit {
  @Input() data: any//接收父组件的值
  @Input() glasseId:any; //选择的眼镜id
 
  constructor(
    private msg:NzMessageService,
    private brandService: BrandService,
    private storageService: StorageService,
    private orderService: OrderService,
    private router: Router,
    private userInfoService:UserInfoService,
  ) { }

  fileUrl=environment.FILE_URL;
  isVisible:boolean=false;
  handleOk():void{
    
  }
  handleCancel():void{
   this.isVisible=false
  }
  // 弹窗打开
  colorName:string;
  modalOpen():void{
    this.getBrands()
    if(this.data.hasOwnProperty('detail')){
      let productId=this.data['colors'].find(item=>item['id']==this.glasseId)['product_id']
      this.colorName=this.data['colors'].find(item=>item['id']==this.glasseId)['color_name']
      this.updateImage(productId);
      
    }
  }
  brands = [];

  @ViewChild('sucessModal') sucessModal;
  title:string;
  NOD:any ;
  //isShow:boolean=false;
  getBrands () {
    let data = {
      access_token: '',
      user_token: this.storageService.get('userToken')
    }
    this.brandService.getBrandList(data).subscribe(
      res=>{
        this.brands = res.data
      }
    )
  }

  saveBtn(item):void{
   // 添加订单
   let param = {
    user_token: this.storageService.get('userToken'),
    glasses_id: this.glasseId?this.glasseId:this.data.detail.id,
    folder_id: item.folder_id,
	type:'0'
  }
    
  
   this.orderService.addOrder(param).subscribe(
    res=>{
      if(res.code=="0"){
		  this.NOD = res.data;
		 
		  if (this.NOD=="3"){
			
		document.getElementById(item.name).style.display="block";
		document.getElementById(item.name).click();  
		  }else{
        this.sucessModal.isVisible=true;
        this.title = `Added to “${item.name}”`;
        this.isVisible=false;
        // 更新用户信息
        this.userInfoService.updataUserInfo();
        setTimeout(()=>{
          this.sucessModal.isVisible=false;
          this.router.navigate(['/my-center/my-order'])
        },2000)
		
		  }
      }else{
        this.msg.error('Fail !')
      }
     
      
    }
  )
   
  }


saveBtn2(item):void{
   // 添加订单
   let param = {
    user_token: this.storageService.get('userToken'),
    glasses_id: this.glasseId?this.glasseId:this.data.detail.id,
    folder_id: item.folder_id,
	type:'1'
  }
    
  
  this.orderService.addOrder(param).subscribe(
    res=>{
      if(res.code=="0"){
		 
        this.sucessModal.isVisible=true;
        this.title = `Added to “${item.name}”`;
        this.isVisible=false;
        // 更新用户信息
        this.userInfoService.updataUserInfo();
        setTimeout(()=>{
          this.sucessModal.isVisible=false;
          this.router.navigate(['/my-center/my-order'])
        },2000)
		
		  
      }else{
        this.msg.error('Fail !')
      }
     
      
    }
  )
   
  }

  // region 增加品牌
  updateBrandBtn():void{
    this.updateBrandFolderModal.isVisible=true;
  }
   @ViewChild('updateBrandFolderModal') updateBrandFolderModal;
   handleOuter(event):void{
     if(event){
       this.getBrands();
     }
 
   }
   // endregion

   // 处理图片
  /**展示的图片 */
  imageList:any[]=[];
  
  updateImage(productId):void{
    // 格式 /zip/55578-BL-A.jpg
    let imgUrlList=[];
    let arr=['A','B','C'];
    arr.forEach(item=>{
      let url=`/zip/${productId}-${item}.jpg`;
      imgUrlList.push(url);
    })
    this.imageList=[...imgUrlList];

  }


  ngOnInit(): void {
   
  }

}
