import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-eye-glasses',
  templateUrl: './eye-glasses.component.html',
})
export class EyeGlassesComponent implements OnInit {

  constructor(
    private routeInfo:ActivatedRoute,
  ) { }
 
  gender:string= this.routeInfo.snapshot.queryParams['gender'];

  ngOnInit() {
  }
  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.gender=this.routeInfo.snapshot.queryParams['gender']
    console.log(this.gender)
    
  }

}
