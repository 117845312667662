import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import{ PassportService} from './../../core/httpservice/passport.service';
import { NzMessageService } from 'ng-zorro-antd';
import {LoginService} from './../../core/public/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private passportSrv:PassportService,
    private msg:NzMessageService,
    private loginService:LoginService,
    private router:Router
    ) {}

  validateForm: FormGroup;
  isLoading:boolean=false;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return ;
    this.isLoading=true;
    this.passportSrv.register(this.validateForm.value).subscribe(
      res=>{
        this.isLoading=false;
        if(res.code==0){
          this.loginService.sendLoginInfo(false);
          this.msg.success('login was successful！');
          setTimeout(() => {
            this.router.navigate(['/sign-in']);
          }, 200)
        }else{
          this.msg.error('login has failed!')
        }
      },
      ()=>{ this.isLoading=false;},
      ()=>{ this.isLoading=false;}
    )

  }
  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      email: [null,[Validators.email, Validators.required]],
      password: [null, [Validators.required]],
    });
   
  }

}