import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ThemeService } from '@core/httpservice/theme.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '@env/environment';
@Component({
  selector: 'app-my-themes',
  templateUrl: './my-themes.component.html',
  styleUrls: ['./my-themes.component.css']
})
export class MyThemesComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,

	    private themeService: ThemeService,
    private storageService: StorageService,
    private msg: NzMessageService,
  ) {
     this.nativeWindow = themeService.getNativeWindow();
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          this.year = this.route.snapshot.queryParams['year'];
		  this.id = this.route.snapshot.queryParams['id'];
		  this.mouse = this.route.snapshot.queryParams['mouse'];
          // this.itemColorCache=new Object();
          // this.isNewFilter=true;
          // this.filterGlassesList();
         // this.currentUrl = this.router.url.split('?')[0].split('/').pop();
          //this.getBannerUrl();
        }
      });
	  this.router.routeReuseStrategy.shouldReuseRoute = () => {
		  return false;
	  };


	  }
	 isLogin: boolean = true;
  a1: any;a2: any;
  title: any;
  mouse: any;
  url: any;
  swf: any;id: string;
  nativeWindow: any
 year: string;
 oneyear: string;
 themList: any[]=[];
 discuss:any[]=[];
 zan:any;
  fileUrl = environment.FILE_URL;

 getThemeList2() {
	  if(!this.year){ this.year="";};
	  this.swf="2";
	  if(!this.id){ 
	  this.swf="1"; 
	 // this.a1="nonono";
	//  this.title=this.storageService.get('userToken');
    let data = {
      user_token: this.storageService.get('userToken'),
	  oneyear:this.year
      //folder_id: this.detailId
    };
    this.themeService.getmyTheme(data).subscribe((res) => {
      if(res.code==0){
       
		this.themList = res.data.list;
		this.a2=JSON.stringify(res.data);
		//this.A2=JSON.stringify(res.data.list);
		 this.year=this.themList[0]['mouse'];
      }else{
		 // this.a2="budui";
        this.themList=[];
		
      }
     
    })
	}else{
		let data = {
        user_token: this.storageService.get('userToken'),
	    id:this.id
        //folder_id: this.detailId
        };
		this.zan="NOZAN";
        this.themeService.getThemeOne(data).subscribe((res) => {
        if(res.code==0){
       
		this.themList = res.data.list;
		//this.a1=this.fileUrl;
		this.url=this.themList[0]['url'];
		this.discuss = res.data.discuss;
		 this.year=this.themList[0]['mouse'];
		 this.title=this.themList[0]['title'];
		 this.zan = res.data.zan;
		 //this.a1=JSON.stringify(this.discuss);
	     }else{
			// this.a1=JSON.stringify(res)
			 }
		})
	}
  }  
  
	goToTheme(id, year): void {
    if (this.isLogin) {
      let postId = id;
     // let groupColor = this.list.find(item => item['id'] == id)['colors'];
     // groupColor.forEach(item => {
     //   if (this.itemColorCache[item.id]['checked']) {
      //    postId = item.id;
     //   }
    //  })
      //  this.router.navigate(['/glasses-detail'],{ queryParams: { id: postId,glassesType:type }})

      this.nativeWindow.open(`/#/luxin-theme?id=${postId}&year=${year}`,'_blank');
    } else {
      this.msg.warning('If you want to see more, please sign in first !');
    }


  }  
  
  display1(di) {
	  //this.msg.success('Success !');
	  var onediv=document.getElementById(di);
	  onediv.style.display="block";
	  
  }
  notdisplay(di) {
	  //this.msg.success('noSuccess !');
	  var onediv=document.getElementById(di);
	  onediv.style.display="none";
	  
  }

  ngOnInit(): void {
	   this.getThemeList2();
	  if (this.storageService.get('userToken')) {
      this.isLogin = true;
	  } else {
      
      this.isLogin = false;
    }
  }

}
