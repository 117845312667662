// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  SERVER_URL: `./api.php/`,
  
     AVATAR_URl:`https://www.luxinoptical.com`,
    FILE_URL:`https://www.luxinoptical.com`, 
	
  production: false,
  useHash: true,
  hmr: false,
};
/*  
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
 
 //  AVATAR_URl:`https://www.luxinoptical.com`,
 //   FILE_URL:`https://www.luxinoptical.com`, 
 
 //  AVATAR_URl:`http://192.168.3.32`,
 //   FILE_URL:`http://192.168.3.32`, 
 
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
