import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import{ UserService} from '@core/httpservice/user.service';
import { StorageService} from '@core/public/storage.service';


@Injectable({ providedIn: 'root' })
export class UserInfoService {
  constructor(private userService:UserService,private storageSrv:StorageService ) { }
  // Observable string sources
  private userInfo = new Subject<string>();

  // Observable string streams
  userInfo$ = this.userInfo.asObservable();

  // Service message commands
  getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }
  // send
  sendUserInfo(mission: any ) {
    this.userInfo.next(mission);
  }
  updataUserInfo():void{
    let data = {
      user_token: this.storageSrv.get('userToken')
    }
    this.userService.getUserInfo(data).subscribe(res => {
      if(res.code==0){
        this.sendUserInfo(res.data) 
      }     
    })
  }
}
