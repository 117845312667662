import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BrandService} from '@core/httpservice/brand.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd';
import {OrderService} from '@core/httpservice/order.service'; 
import { Router} from '@angular/router';
@Component({
  selector: 'app-submit-brand-folder-modal',
  templateUrl: './submit-brand-folder-modal.component.html',
  styleUrls: ['./submit-brand-folder-modal.component.css'],
  styles: [
    `
      ::ng-deep .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ::ng-deep .vertical-center-modal .ant-modal {
        top: 0;
      }
      ::ng-deep .ant-modal-close{
        left:0;
      }
      ::ng-deep .ant-modal-content{
        border-radius: 20px;
      }
      ::ng-deep .ant-card-head{
        background-color: #1b1923;
      }
      [nz-form] {
        max-width: 600px;
      }

      .phone-select {
        width: 70px;
      }

      .register-are {
        margin-bottom: 8px;
      }
      ::ng-deep .ant-checkbox-inner{
        border: 2px solid #000;
    
      }
    
    `
  ]
})
export class SubmitBrandFolderModalComponent implements OnInit {
  constructor(
    private brandService: BrandService, 
    private  storageService:  StorageService,
    private msg:NzMessageService,
    private orderService: OrderService,
    private router:Router
    ) {}


  @Output() outer=new EventEmitter<boolean>();

   //region  我的订单
   orderList:any[]=[];
   spinning:boolean=false;
   getMyOrder () {
    let data  = {
      user_token: this.storageService.get('userToken')
    }
    this.spinning=true;
    this.orderService.getOrderList(data).subscribe(
      (res) => {
        this.spinning=false;
      if(res.code==0){
        this.orderList = res.data
        if(this.orderList.length>0){
          this.updateOrderCache();
        }
      }else{
        this.orderList=[];
      }
     
    },
    ()=>{ this.spinning=false;},
    ()=>{ this.spinning=false;},
    )
  }
  /**存储订单 */
  orderCache={};
  /**更新存储订单 */
  updateOrderCache():void{
    this.orderCache=new Object();
    this.orderList.forEach(item => {
      if (!this.orderCache[item.folder_id]) {
        this.orderCache[item.folder_id] = {
          checked: false,
          ...item,
        };
      }
    });
  }
  /**选择订单 */
  selecedOrder(folder_id):void{
    this.orderCache[folder_id]['checked']=!this.orderCache[folder_id]['checked'];
    this.updateSelectedFolderId();
  }
  /**选择的订单id */
  selectedIds=[];
  /** 更新选择的品牌 */
  updateSelectedFolderId():void{
    let selectedIds=[];
    for(let key in this.orderCache){
      if(this.orderCache[key]['checked']){
        selectedIds.push(key)
      }
    }
    this.selectedIds=[...selectedIds];
    // console.log(this.selectedIds)
  }
  
  // endregion 

  loading:boolean=false;

  submitForm(): void {
    let data = {
      user_token: this. storageService.get('userToken'),
      folderIds:this.selectedIds.join(',')
    }
    this.loading=true;
    this.orderService.submitOrder(data).subscribe(
      (res) => {
        this.loading=false;
      if(res.code==0){
       this.msg.success('Success !');
       this.isVisible=false;
       this.outer.emit(true);
      
      }else{
        this.msg.success('Fail !')
      }
     
    },
    ()=>{this.loading=false},
    ()=>{this.loading=false}
    )
  
  
   
   
  }
 
  isVisible:boolean=false;

  handleCancel():void{
    this.isVisible=false
  }
  modalOpen():void{
    this.selectedIds=[];
    this.getMyOrder();
    
  }

   

  

  ngOnInit(): void {
   
  }

}
