
/*
 * @Description: 业务请求
 * @Author: cy
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}
  
  getOrderList(data?: any): Observable<any> {
    return this.http.post<any>(`Order/myOrderList`, data);
  }
  
  getOrderDetail(param?: any): Observable<any> {
    return this.http.post<any>('Order/myOrderDetail', param)
  }

  addOrder(param?: any): Observable<any> {
    return this.http.post<any>('Order/addMyOrder', param)
  }
  updateComment(param?: any): Observable<any> {
    return this.http.post<any>('Order/updateComment', param)
  }
  
  /**删除订单下眼镜*/
  delGlasses(param?: any): Observable<any> {
    return this.http.post<any>('Order/delGlasses', param)
  }
  /**提交订单*/
  submitOrder(param?: any): Observable<any> {
    return this.http.post<any>('Order/submitOrder', param)
  }
  /**订单导出*/
  orderExport(param?: any): Observable<any> {
    return this.http.post<any>('Execl/export', param)
  }

  


}
