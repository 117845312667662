
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs'
@Injectable({ providedIn: 'root' })

export class GlassesInfoService {
  constructor() { }
  // Observable string sources
  private glassesInfo = new Subject<string>();

  // Observable string streams
  glassesInfo$ = this.glassesInfo.asObservable();

  // Service message commands
  getGlassesInfo(): Observable<any> {
    return this.glassesInfo.asObservable();
  }
  // send
  sendGlassesInfo(mission: any) {
    this.glassesInfo.next(mission);
  }
}
