import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

   // 判断当前路由
   currentUrl: string = this.router.url.split('?')[0].split('/').pop();

  constructor(  
    private router: Router,
    private routeInfo: ActivatedRoute,
   ) {
    this.router.events
    .subscribe((event: NavigationEnd) => {
      if(event instanceof NavigationEnd){
        this.currentUrl = this.router.url.split('?')[0].split('/').pop();
        
      }
    });
  }

  ngOnInit() {
  }

}
