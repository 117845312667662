import { Component, OnInit } from '@angular/core';
import { FavoriteService} from '@core/httpservice/favorite.service';
import { StorageService} from '@core/public/storage.service';
import {environment} from '@env/environment';
import {GlassesService} from '@core/httpservice/glasses.service';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-favorites',
  templateUrl: './my-favorites.component.html',
  styleUrls: ['./my-favorites.component.css']
})
export class MyFavoritesComponent implements OnInit {

  constructor(
    private favoriteService: FavoriteService,
     private storageService: StorageService,
     private glassesService:GlassesService,
     private router: Router,
     private route: ActivatedRoute,
     ) {
      this.nativeWindow = glassesService.getNativeWindow();
      }
 
  list:any[]=[];
  sort:any
  fileUrl=environment.FILE_URL;
  // windows 对象
  nativeWindow: any

  // region 获取属性
  getGlassesAttribute():void{
    let data={
      user_token:this.storageService.get('userToken')
    }
    this.glassesService.myFavoriteAttribute(data).subscribe(
      res=>{
        this.colorList=[...res.data.color.map(item=>{item['checked']=false; return item} )];
        this.shapeList=[...res.data.shape.map(item=>{item['checked']=false; return item} )];;
        this.materialList=[...res.data.material.map(item=>{item['checked']=false; return item} )];
    });
  }
  // endregion
  // region 颜色
  color:any[]=[];
  colorList:any[]=[];
  colorChange(event:any[]):void{
    this.color=event;
    this.getMyFavorite();
  }
  // endregion
  // region 形状
  shape:any[]=[];
  shapeList = [ ];
  shapeChange(event:any[]):void{
    this.shape=event;
    this.getMyFavorite();
  }
  // endregion
  // regin 材料
  material:any[]=[];
  materialList = [];
  materialChange(event:any[]):void{
    this.material=event;
    this.getMyFavorite();
  }

  // endregion

  

   //**选择筛选项目 */
   currentScreenType:any='isNew';
   selectedType(type):void{
     this.currentScreenType=type;
     this.isShowAllColor=false;
     if(type=="isNew"){
      this.isNewFilter=true;
      this.getMyFavorite();

    }else{
      this.isNewFilter=false;
      if(this.currentScreenType=='reset'){
        this.resetFilter();
      }else{
        this.getMyFavorite();
      }
      
    }
    
   }

     // region 颜色隐藏
  isShowAllColor:boolean=false;
  showMoreColorChange():void{
    this.isShowAllColor=!this.isShowAllColor;
  }
  // endregion 
  

   /**重置筛选按钮 */
   resetFilter():void{
      this.colorList.map(item=>{item['checked']=false;return item});
    this.materialList.map(item=>{item['checked']=false;return item});
    this.shapeList.map(item=>{item['checked']=false;return item});
    this.color=[];
    this.shape=[];
    this.material=[];
    this.getMyFavorite();
    }




  // 获取收藏列表
  isNewFilter:boolean=true;
  getMyFavorite () {
    if(this.isNewFilter){
      let data={
        sort:this.sort?this.sort:'DESC',
        user_token: this.storageService.get('userToken'),
      }
      for(let key in data){
        if(!data[key]){
          delete data[key]
        }
      }
      this.favoriteService.myNewFavorite(data).subscribe(
        (res) => {
          if(res.code=='0'){
            this.list = res.data;
            if(res.data.length>0){
              this.updateItemColorCache();
            }
            
          }else{
            this.list = []
          }
        }
      )
      
    }else{
      let data  = {
        user_token: this.storageService.get('userToken'),
        sort:this.sort?this.sort:'DESC',
        color:this.color.length>0? this.color.join(','):'',
        material:this.material.length>0?this.material.join(','):'',
        shape:this.shape.length>0?this.shape.join(','):'',
      }
      for(let key in data){
        if(!data[key]){
          delete data[key]
        }
      }
      this.favoriteService.getFavoriteList(data).subscribe((res) => {
        if(res.code=='0'){
          this.list = res.data;
          if(res.data.length>0){
            this.updateItemColorCache();
          }
        }else{
          this.list = []
        }
        
      })

    }
   
  }

 //region  选择眼镜id
 itemColorCache={};
 updateItemColorCache():void{
   this.itemColorCache=new Object();
   if(this.list.length>0){
     this.list.forEach(item=>{
       item['colors'].forEach(color=>{
         let checked=false;
         if(item.id==color.id){
           checked=true;
         }
         if (!this.itemColorCache[color.id]) {
           this.itemColorCache[color.id] = {
             checked: checked,
            ...color
           };
         }
       })
     })
   }
 }
 // endregin

 // 跳转眼镜详情
 // [routerLink]="['/glasses-detail']" [queryParams]="{id:item.id, glassesType:item.glasses_type}"
 goToDetail(id,type):void{
   let postId=id;
    let groupColor= this.list.find(item=>item['id']==id)['colors'];
    groupColor.forEach(item=>{
      if(this.itemColorCache[item.id]['checked']){
       postId=item.id;
      }
    })
    // this.router.navigate(['/glasses-detail'],{ queryParams: { id: postId,glassesType:type }})
    this.nativeWindow.open(`/#/glasses-detail?id=${postId}&glassesType=${type}`);
 }
 // 颜色选择控制
 selectColorChange(groupColorId,colorId):void{
   let groupColor= this.list.find(item=>item['id']==groupColorId)['colors'];
   groupColor.forEach(item=>{
     this.itemColorCache[item.id]['checked']=false;
   })
   this.itemColorCache[colorId]['checked']=true;

 }

  ngOnInit(): void {
    // this.isNewFilter=false;
    // this.currentScreenType='reset'
    this.isNewFilter=true;
    this.currentScreenType='isNew';
    this.getMyFavorite();
    this.getGlassesAttribute();
  }

}
