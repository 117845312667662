import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {PassportService} from './../../../core/httpservice/passport.service'
import { NzMessageService } from 'ng-zorro-antd';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';

// resetPassword

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.css']
})
export class ForgotPasswordModalComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private passportSrv:PassportService,
    private msg:NzMessageService,
    private router:Router,
    private route:ActivatedRoute,
    ) {
      this.validateForm = this.fb.group({
        email: [null, [Validators.email, Validators.required]],
      });
      this.resetForm = this.fb.group({
        password: [null, [Validators.required]],
        // code: [null, [Validators.required]],
      });

      this.router.events.subscribe((event: NavigationEnd) => {
        if(event instanceof NavigationEnd){
          if(this.route.snapshot.queryParams.hasOwnProperty('code')){
            this.isVisible=true;
           this.isShowReset=true;
           this.validateForm.reset();
           this.resetForm.reset({
            //  code:this.route.snapshot.queryParams['code'],
             password:null
           });

          }
        }
      });
      
    }

  validateForm: FormGroup;
  resetForm: FormGroup;
  @Input() isVisible:boolean;
  // 重置密码
  isShowReset:boolean=false;
  showReset():void{
    this.isShowReset=!this.isShowReset;
    if(this.isShowReset){
      this.resetForm.reset();
    }else{
      this.validateForm.reset();
    }
  }

  // 发送验证码
  loading:boolean=false;
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return ;
    this.loading=true;
    this.passportSrv.resetPassword(this.validateForm.value).subscribe(
      res=>{
        this.loading=false;
        if(res.code==0){
          // this.msg.success('Mail has been sent to your mailbox!')
          this.msg.success('success !')
          this.showReset();
        }else{
          this.msg.error('fail !')
        }
      },
      ()=>{ this.loading=false;},
      ()=>{ this.loading=false;}
    )
  }

  // 重置密码
  resetloading:boolean=false;
  submitResetForm():void{
    for (const i in this.resetForm.controls) {
      this.resetForm.controls[i].markAsDirty();
      this.resetForm.controls[i].updateValueAndValidity();
    }
    if (this.resetForm.invalid) return ;
    this.resetloading=true;
    let data={
      code:this.route.snapshot.queryParams['code']
    }
    Object.assign(data,this.resetForm.value)
    this.passportSrv.confirmResetPassword(data).subscribe(
      res=>{
        this.resetloading=false;
        if(res.code==0){
          // this.msg.success('Mail has been sent to your mailbox!')
          this.msg.success('success !')
        }else{
          this.msg.error('fail !')
        }
      },
      ()=>{ this.resetloading=false;},
      ()=>{ this.resetloading=false;}
    )
  }
  

  // 关闭弹窗
  closeModal():void{
    this.isVisible=!this.isVisible;
    if(!this.isVisible){
      this.validateForm.reset();
      this.resetForm.reset();
    }
  }

  ngOnInit(): void {
    if(this.route.snapshot.queryParams.hasOwnProperty('code')){
      console.log(this.route.snapshot.queryParams['code'])
      this.isVisible=true;
     this.isShowReset=true;
     this.validateForm.reset();
     this.resetForm.reset({
       code:this.route.snapshot.queryParams['code'],
       password:null
     });

    }else{
    this.isShowReset=false;
    this.isVisible=false;
    }
   
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.validateForm.reset();
    this.resetForm.reset();
    
    
  }

}
