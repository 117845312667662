import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { httpInterceptorProviders } from './core/net/index.interceptor';

import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule, NZ_ICON_DEFAULT_TWOTONE_COLOR, NZ_ICONS } from 'ng-zorro-antd/icon';

// 引入需要的图标，比需要 fill 主题的 AccountBook Alert 和 outline 主题的 Alert
import { 
  HeartOutline, 
  ShoppingCartOutline, 
  PlusOutline, PlusCircleFill, 
  LogoutOutline, UserOutline, 
  LockOutline, MailOutline,
   EditOutline ,
   CaretLeftOutline,
   HeartFill,
   FolderFill,
  } from '@ant-design/icons-angular/icons';
const icons: IconDefinition[] = [
  HeartOutline, ShoppingCartOutline, PlusOutline, PlusCircleFill, LogoutOutline, UserOutline, LockOutline, MailOutline, 
  EditOutline,CaretLeftOutline,HeartFill,FolderFill];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    RoutesModule,
    SharedModule,
    NzIconModule,
    HttpClientModule
	
  ],
  providers: [
    httpInterceptorProviders,
    { provide: NZ_ICON_DEFAULT_TWOTONE_COLOR, useValue: '#e8831f' }, // 不提供的话，即为 Ant Design 的主题蓝色
    { provide: NZ_ICONS, useValue: icons },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
