

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-sun-glasses',
  templateUrl: './sun-glasses.component.html',
})
export class SunGlassesComponent implements OnInit {

  constructor(
    private routeInfo:ActivatedRoute,
  ) { }
 
  gender:string= this.routeInfo.snapshot.queryParams['gender'];

  ngOnInit() {
  }

}
