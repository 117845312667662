import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router'
import { ThemeService } from './../../core/httpservice/theme.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '@env/environment';

@Component({
  selector: 'app-filter-by-year',
  templateUrl: './filter-by-year.component.html',
  styleUrls: ['./filter-by-year.component.css']
})
export class FilterByYearComponent implements OnInit {

  constructor(
   private fb: FormBuilder,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private themeService: ThemeService,
    private msg: NzMessageService,
  ) { }
  detailId: any;
  listOfControl: Array<{  year: string ;num: number }> = [];
  themList: any[]=[];
  
  getThemeList() {
    let data = {
      user_token: this.storageService.get('userToken')
      //folder_id: this.detailId
    }
    this.themeService.getThemeList(data).subscribe((res) => {
      if(res.code==0){
        this.themList = res.data.labels;
		
      }else{
        this.themList=[];
      }
     
    })
  }
  ngOnInit() {
	  
    // this.validateForm = this.fb.group({});
	
    this.getThemeList()
    // this.addField();
  }

}
