import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './../routes/home/home.component';
import {Home_mComponent} from './../routes/home/home_m.component';
import {DefaultComponent} from './../layout/default/default.component';
import { LuxinThemeComponent } from './luxin-theme/luxin-theme.component';
import { LuxinTheme_mComponent } from './luxin-theme/luxin-theme_m.component';
import { EyeGlassesComponent } from './eye-glasses/eye-glasses.component';
import { GlassesDetailComponent } from './glasses-detail/glasses-detail.component';
import { MyOrderComponent } from './my-center/my-order/my-order.component';
import { MyFavoritesComponent } from './my-center/my-favorites/my-favorites.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { Userwho } from './sign-up/user-who.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditMyBrandDetailComponent } from './edit-my-brand-detail/edit-my-brand-detail.component';
import { MyThemesComponent } from './my-center/my-themes/my-themes.component';
import { SunGlassesComponent } from './sun-glasses/sun-glasses.component';
import { MyCenterComponent } from './my-center/my-center.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent,
		
      },
	  {
        path: 'home_m',
        component: Home_mComponent,
		
      },
      {
        path: 'luxin-theme',
        component: LuxinThemeComponent,
      },
	   {
        path: 'luxin-theme_m',
        component: LuxinTheme_mComponent,
      },
      {
        path: 'eye-glasses',
        component: EyeGlassesComponent,
      },
      {
        path: 'sun-glasses',
        component: SunGlassesComponent,
      },
      {
        path: 'glasses-detail',
        component: GlassesDetailComponent,
      },
      {
        path: 'my-center',
        component: MyCenterComponent,
        children:[
          {
            path: 'my-order',
            component: MyOrderComponent,
          },
          {
            path: 'my-favorites',
            component: MyFavoritesComponent,
          },
          {
            path: 'my-themes',
            component: MyThemesComponent,
          },
          {
            path: 'edit-my-brand-detail',
            component: EditMyBrandDetailComponent,
          },
        ]

      }, 
      {
        path: 'sign-in',
        component: SignInComponent,
      },
      {
        path: 'sign-up',
        component: SignUpComponent,
      },
	  {
        path: 'user-who',
        component: Userwho,
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
      },
      
    ],
  },
  
 
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true}),],
  exports: [RouterModule]
})
export class RouteRoutingModule { }
