
import { Injectable } from '@angular/core';
// import { SECRET } from '@core/config/http-net-config';
// import Base64 from 'crypto-js/enc-base64.js';
// import HmacSHA1 from 'crypto-js/hmac-sha1.js'
@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {

  constructor() { }
  /**
   * 签名计算
   *  @example
   * Authorization:hmac id="secret_id", algorithm="hmac-sha1", headers="x-date source", signature="Base64(HMAC-SHA1(signing_str, secret_key))
   * */
  // public getAuthorization() {
  //   let date = new Date().toUTCString();
  //   let source = `tongsupc`;
  //   let signStr = `x-date: ${date}\nsource: ${source}`;
  //   let sign = HmacSHA1(signStr, SECRET.key);
  //   let signature = Base64.stringify(sign);
  //   let authorization = `hmac id="${SECRET.id}", algorithm="hmac-sha1", headers="x-date source", signature="${signature}"`;
  //   let headers = {
  //     'Authorization': authorization,
  //     'X-Date': date,
  //     'Source': source
  //   }
  //   return headers;
  // }

  /**
   * @param obj　参数对象
   * @return {string}　参数字符串
   * @example
   *  声明: var obj= {'name':'小军',age:23};
   *  调用: toQueryString(obj);
   *  返回: "?name=%E5%B0%8F%E5%86%9B&age=23"
   */
  public toQueryString(obj) {
    let ret = [];
    for (let key in obj) {
      key = encodeURIComponent(key);
      let values = obj[key];
      if (values && values.constructor == Array) {
        //数组
        let queryValues = [];
        for (let i = 0, len = values.length, value; i < len; i++) {
          value = values[i];
          queryValues.push(this.toQueryPair(key, value));
        }
        ret = ret.concat(queryValues);
      } else {
        //字符串
        ret.push(this.toQueryPair(key, values));
      }
    }
    return '?' + ret.join('&');
  }

  /**
   *
   * @param obj
   * @return {string}
   *  声明: var obj= {'name':'小军',age:23};
   *  调用: toQueryString(obj);
   *  返回: "name=%E5%B0%8F%E5%86%9B&age=23"
   */
  public toBodyString(obj) {
    let ret = [];
    for (let key in obj) {
      key = encodeURIComponent(key);
      let values = obj[key];
      if (values && values.constructor == Array) {
        //数组
        let queryValues = [];
        for (let i = 0, len = values.length, value; i < len; i++) {
          value = values[i];
          queryValues.push(this.toQueryPair(key, value));
        }
        ret = ret.concat(queryValues);
      } else {
        //字符串
        ret.push(this.toQueryPair(key, values));
      }
    }
    return ret.join('&');
  }

  private toQueryPair(key, value) {
    if (typeof value == 'undefined') {
      return key;
    }
    return key + '=' + encodeURIComponent(value === null ? '' : String(value));
  }
}
