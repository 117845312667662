import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router'
import { OrderService } from './../../core/httpservice/order.service';
import { StorageService } from './../../core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '@env/environment';

@Component({
  selector: 'app-edit-my-brand-detail',
  templateUrl: './edit-my-brand-detail.component.html',
  styleUrls: ['./edit-my-brand-detail.component.css'],
  styles: [
    `
      [nz-form] {
        max-width: 600px;
      }

      .phone-select {
        width: 70px;
      }

      .register-are {
        margin-bottom: 8px;
      }
       .ant-descriptions-item{
        padding-top: 16px;
        border-bottom: 2px solid #ccc;
    }
    .ant-descriptions-item{
        padding-top: 16px;
        border-bottom: 2px solid #ccc;
    }
    `
  ]
})
export class EditMyBrandDetailComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private orderService: OrderService,
    private msg: NzMessageService,

  ) { }
  detailId: any;
  listOfControl: Array<{ id: number; code: string }> = [];
  fileUrl = environment.FILE_URL;

  validateForm: FormGroup;
  detailData: {
    folderName: '',
    list: [{
      validateForm: {
        code: 'xx',
      }
    }]
  }
  // submitForm(): void {
  //   for (const i in this.validateForm.controls) {
  //     this.validateForm.controls[i].markAsDirty();
  //     this.validateForm.controls[i].updateValueAndValidity();
  //   }
  // }
  // 获取详情
  getDetail() {
    let param = {
      user_token: this.storageService.get('userToken'),
      folder_id: this.detailId
    }
    this.orderService.getOrderDetail(param).subscribe(
      res => {
      this.detailData = res.data
      // console.log(res.data)
      if( res.data.list.length>0){
        res.data.list.map(item => {
          item['glasses_url_B'] = `/zip/${item['product_id']}-B.jpg`;
          item['is_already_chosen'] = false;
          return item;
  
        })
        let productIdArr = [];
        res.data.list.forEach(item => {
          productIdArr.push(item['product_id'])
        })
        this.listOfControl =[...res.data.list] 
        console.log(res.data.list)
        this.getRepeated(productIdArr)
      }

      
      //   this.controlArray = res.data.list
    })
  }

  // region 获取重复项的方法
  getRepeated(arr: any[]): void {
    let rep = [];
    arr.forEach((item, index) => {
      if (arr.indexOf(item) != index) { // 匹配数组元素第一个item位置和当前循环的index
        rep.push(index );
      }
    });
    rep.forEach(item=>{
     this.listOfControl[item]['is_already_chosen']=true;
    })
    console.log(this.listOfControl)
  }

  // endregion


  // 保存
  save(item) {
    let params = {
      user_token: this.storageService.get('userToken'),
      id: item.id,
      comment: item.comment
    }
    this.orderService.updateComment(params).subscribe(
      res => {
        if (res.code == 0) {
          this.msg.success('Success !')
        } else {
          this.msg.error('Fail !')
        }

      })

  }

  // 修改订单弹窗
  curentAction: string = "edit";
  @ViewChild('updateBrandFolderModal') updateBrandFolderModal;
  updateBrandBtn(action): void {
    this.curentAction = action;
    this.updateBrandFolderModal.isVisible = true;
  }
  handleOuter(event): void {
    if (event) {
      this.getDetail();
    }
  }

  /**删除眼镜目录 */
  delItemconfirm(id): void {
    let data = {
      user_token: this.storageService.get('userToken'),
      id: id
    }
    this.orderService.delGlasses(data).subscribe(
      res => {
        if (res.code == "0") {
          this.msg.success('Success !');
          this.getDetail();
        } else {
          this.msg.error('Fail !')
        }
      }
    )
  }



  // addField(e?: MouseEvent): void {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   const id = this.listOfControl.length > 0 ? this.listOfControl[this.listOfControl.length - 1].id + 1 : 0;

  //   const control = {
  //     id,
  //     code: `passenger${id}`
  //   };
  //   // const index = this.listOfControl.push(control);
  //   // console.log(this.listOfControl[this.listOfControl.length - 1]);
  //   this.validateForm.addControl(
  //     this.listOfControl[index - 1].code,
  //     new FormControl(null, Validators.required)
  //   );
  // }
  ngOnInit(): void {
    this.detailId = this.route.snapshot.queryParams['id']
    // this.validateForm = this.fb.group({});
    this.getDetail()
    // this.addField();
  }

}
