import { Component, OnInit,HostListener } from '@angular/core';
import { LoginService} from '@core/public/login.service';
import { Router } from '@angular/router';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd';
import{ UserService} from '@core/httpservice/user.service';
import {UserInfoService} from '@core/public/user-info.service';
import {environment} from '@env/environment';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css']
})
export class TopHeaderComponent implements OnInit {

  constructor( 
    private loginService:LoginService,
    private msg:NzMessageService,
    private router:Router,
    private storageSrv:StorageService,
    private userService: UserService,
    private userInfoService:UserInfoService 
    ) { }

    fileUrl=environment.FILE_URL;
    avatarHeader=environment.AVATAR_URl;

  


  // 是否登录
  isLogin:boolean=false;
 

  // 退出登陆
  logoutBtn():void{
    this.loginService.sendLoginInfo(false);
    this.storageSrv.remove('userToken');
    this.msg.success('Exit successfully!');
     //  跳转首页
     setTimeout(() => {
      this.router.navigate(['/sign-in']);
    }, 200)

  }

  userInfo:any={};
  //获取用户信息
  getUserInfo() {
    let data = {
      user_token: this.storageSrv.get('userToken')
    }
    this.userService.getUserInfo(data).subscribe(res => {
      if(res.code==0){
        this.userInfo = res.data;  
      }     
    })
  }
  
  ngOnInit() {
   
    this.loginService.getLoginInfo().subscribe(
      res=>{
       
        this.userInfoService.updataUserInfo();
        if(res){
          this.isLogin=true;
         
          
        }else{
          this.isLogin=false;
        }
      }
    )
    if(this.storageSrv.get('userToken')){
      this.isLogin=true;
    }
    this.userInfoService.updataUserInfo();
    this.userInfoService.getUserInfo().subscribe(
      res=>{
        this.userInfo=res;
      }
    )

  }

  

}
