import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpClient
} from '@angular/common/http';
import { Observable, of, throwError, from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd';
import { environment } from './../../../environments/environment';
import { NzNotificationService } from 'ng-zorro-antd';
import{HttpHeaderService } from './../public/http-header.service';

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private nzNotificationService: NzNotificationService,
    private headerSrv:HttpHeaderService
  ) {}

  get msg(): NzMessageService {
    return this.injector.get(NzMessageService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  /** 请求成功的回调 */
  private handleData(
    event: HttpResponse<any> | HttpErrorResponse,
  ): Observable<any> {
    // 业务处理：一些通用操作
    switch (event.status) {
      case 200:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
        // 例如响应内容：
        //  错误内容：{ status: 1, msg: '非法参数' }
        //  正确内容：{ status: 0, response: {  } }
        // 则以下代码片断可直接适用
        if (event instanceof HttpResponse) {
          const body: any = event.body;
          if (body) {
            switch (parseInt(body.code)) {
              case 200:
                break;
              case 202:
                this.msg.error(body.msg);
                break;
              case 301:
                this.msg.error(body.msg);
                break;
              default:
                break;
            }
            // 继续抛出错误中断后续所有 Pipe、subscribe 操作，因此：
            // this.http.get('/').subscribe() 并不会触发
            return of(event);
          } else {
            // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
            // return of(
            //   new HttpResponse(Object.assign(event, { body: body.response })),
            // );
            // 或者依然保持完整的格式
            return of(event);
          }
        }
        break;
    }
    return of(event);
  }

  /** 请求失败的回调 */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.warn(
        '未可知错误，大部分是由于后端不支持CORS或无效配置引起',
        event,
      );
      this.nzNotificationService.error(`网络超时`, '请重试');
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      switch (error.status) {
        case 1000005: // 未登录状态码
        this.msg.warning('Login expired, please login again !');
        setTimeout(()=>{
          this.goTo('/sign-in');
        },50)
          break;
        case 403:
        case 404:
          // this.nzNotificationService.error('404', '未找到资源，请重试');
          break;
        case 500:
          // this.nzNotificationService.error('500', '抱歉，服务器出错了');
          break;
        default:
          if (event instanceof HttpErrorResponse) {
            console.warn(
              '未可知错误，大部分是由于后端不支持CORS或无效配置引起',
              event,
            );
            this.nzNotificationService.error(
              `${event.status}网络超时`,
              '请重试',
            );
          }
          break;
      }
    }
    // return an observable with a user-facing error message
    let isError = true;
    return throwError(isError);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    // 统一加上服务端前缀
    let url = req.url;
	// console.log(url);
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
		
      url = environment.SERVER_URL + url;
     //console.log(url);
    //this.headerSrv.toBodyString(req.urlWithParams)
    let body = this.headerSrv.toBodyString(req.body);
    // let authheader: any = this.headerSrv.getAuthorization();
    let Header = {
      'Content-Type': 'application/x-www-form-urlencoded',
      charset: 'utf-8',
    };
  
    // if (isIotApi) {
    //   Header = {
    //     'Content-Type': 'application/json',
    //     charset: 'utf-8',
    //   };
    // }
    // signature
    const newReq = req.clone({
      url: url,
      setHeaders: Header,
      body: body,
      withCredentials: true,
    });
    //newReq.serializeBody();
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理，这是因为一个请求若是业务上错误的情况下其HTTP请求的状态是200的情况下需要
        if (event instanceof HttpResponse && event.status === 200)
			
          return this.handleData(event);
        // 若一切都正常，则后续操作
        return of(event);
		//console.log(event);
		console.log('AAAA');
      }),
      catchError((err: HttpErrorResponse) => {
		
        return this.handleError(err);
      }),
    );
  }
}
}