
/*
 * @Description: 业务请求
 * @Author: cy
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  constructor(private http: HttpClient) {}
  
  /**列表 */
  getFavoriteList(data?: any): Observable<any> {
    return this.http.post<any>(`Favorite/myFavorite`, data);
  }
  /**收藏眼镜 */
  addFavorites(data?: any): Observable<any> {
    return this.http.post<any>(`Favorite/addFavorites`, data);
  }
  /**取消收藏眼镜 */
  cancelFavorites(data?: any): Observable<any> {
    return this.http.post<any>(`Favorite/cancelFavorites`, data);
  }
  /**取消收藏眼镜 */
  myNewFavorite(data?: any): Observable<any> {
    return this.http.post<any>(`Favorite/myNewFavorite`, data);
  }
}
