import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default/default.component';
import { SharedModule } from './../shared/shared.module';
import { TopHeaderComponent } from './default/header/top-header/top-header.component';
import { MainNavbarComponent } from './default/header/main-navbar/main-navbar.component';
import { ContactsComponent } from './default/footer/contacts/contacts.component';
import { PageFooterComponent } from './default/footer/page-footer/page-footer.component';
import { ChooseGlassesModalComponent } from './default/header/choose-glasses-modal/choose-glasses-modal.component';

const COMPONENTS = [
  DefaultComponent,
  TopHeaderComponent,
  MainNavbarComponent,
  ContactsComponent,
  PageFooterComponent,
  ChooseGlassesModalComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [...COMPONENTS ],
  exports: [...COMPONENTS],
})
export class LayoutModule { }
