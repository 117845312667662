import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.css']
})
export class MainNavbarComponent implements OnInit {
  // 判断当前路由
 currentUrl: string = this.router.url.split('?')[0].split('/').pop();
  constructor(
    private router: Router,
    private routeInfo: ActivatedRoute,

  ) { 
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if(event instanceof NavigationEnd){
          this.currentUrl = this.router.url.split('?')[0].split('/').pop();
          if(this.routeInfo.snapshot.queryParams.hasOwnProperty('glassesType')){
            this.glassesType=this.routeInfo.snapshot.queryParams['glassesType']
          }
        }
      });
  }

  isVisible:boolean=false;
  currentOtherUrl:any;
  
  // 判断眼镜详情页激活的路由
  glassesType='';


  //region 弹窗选择
  @ViewChild('chooseGlassesModal') chooseGlassesModal;
  selectedUrl(url):void{
    this.currentOtherUrl=url;
    this.isVisible=!this.isVisible;
    this.chooseGlassesModal.isVisible=this.isVisible;
  }
  closeModal():void{
    this.isVisible=false;
  }
  handleResult($event):void{
    this.isVisible=$event
    this.currentOtherUrl=null;
  }
  // endregion

  // 跳转联系我们
  gotoCantact():void{
    // href="#idsContanct" 
    // console.log(this.router)
  }

  

  ngOnInit() {
  }

}
