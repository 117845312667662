import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BrandService} from '@core/httpservice/brand.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd';
import {OrderService} from '@core/httpservice/order.service'; 
import { Router} from '@angular/router';
@Component({
  selector: 'app-update-brand-folder-modal',
  templateUrl: './update-brand-folder-modal.component.html',
  styleUrls: ['./update-brand-folder-modal.component.css'],
  styles: [
    `
      ::ng-deep .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ::ng-deep .vertical-center-modal .ant-modal {
        top: 0;
      }
      ::ng-deep .ant-modal-close{
        left:0;
      }
      ::ng-deep .ant-modal-content{
        border-radius: 20px;
      }
      ::ng-deep .ant-card-head{
        background-color: #1b1923;
      }
      [nz-form] {
        max-width: 600px;
      }

      .phone-select {
        width: 70px;
      }

      .register-are {
        margin-bottom: 8px;
      }
    `
  ]
})
export class UpdateBrandFolderModalComponent implements OnInit {
  constructor(
    private fb: FormBuilder, 
    private brandService: BrandService, 
    private  storageService:  StorageService,
    private msg:NzMessageService,
    private orderService: OrderService,
    private router:Router
    ) {}

  @Input() action:string;
  @Input() brandId:any;
  validateForm: FormGroup;
  @Output() outer=new EventEmitter<boolean>();

  loading:boolean=false;

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.invalid) return ;
     // 添加品牌目录
    if(this.action=="add"){
      let data = {
        user_token: this. storageService.get('userToken'),
        name: this.validateForm.value.brandName,
        note: this.validateForm.value.note
      }
      this.loading=true;
      this.brandService.addBrand(data).subscribe(
        res=>{
          this.loading=false;
          if(res.code=='0'){
            this.msg.success('Success!')
          }else{
            this.msg.error('Fail!')
          }
          this.handleCancel()
          this.outer.emit(true)
        },
        ()=>{ this.loading=false;},
        ()=>{ this.loading=false;},
      )
    }else{
      // 编辑
      let data={
        user_token: this. storageService.get('userToken'),
        name: this.validateForm.value.brandName,
        note: this.validateForm.value.note,
        id:this.brandId
      }
      this.loading=true;
      this.brandService.editBrandFolder(data).subscribe(
        res=>{
          this.loading=false;
          if(res.code=='0'){
            this.msg.success('Success!')
          }else{
            this.msg.error('Fail!')
          }
          this.handleCancel()
          this.outer.emit(true)
        },
        ()=>{ this.loading=false;},
        ()=>{ this.loading=false;},
      )
    }
  
   
   
  }
 
  isVisible:boolean=false;

  handleCancel():void{
    this.isVisible=false
  }
  modalOpen():void{
    if(this.action=="edit"){
       this.getDetail();
    }else{
      this.validateForm.reset();
    }
  }

   // 获取详情
   getDetail() {
    let param = {
      user_token: this.storageService.get('userToken'),
      folder_id: this.brandId
    }
    this.orderService.getOrderDetail(param).subscribe(res => {
      if(res.code==0){
        this.validateForm.reset({
          brandName:res.data.folderName,
          note:res.data.folderNote?res.data.folderNote:'',
        })
      }
     
      
    })
  }

  /** 删除订单详情 */
  delloading:boolean=false;
  deleteMyOrder():void{
     // 编辑
     let data={
      user_token: this. storageService.get('userToken'),
      id:this.brandId,
      is_void:1,
    }
    this.delloading=true;
    this.brandService.editBrandFolder(data).subscribe(
      res=>{
        this.delloading=false;
        if(res.code=='0'){
          this.msg.success('Success!')
          this.handleCancel()
          this.router.navigate(['/my-center/my-order'])
          
        }else{
          this.msg.error('Fail!')
        }
      },
      ()=>{ this.delloading=false;},
      ()=>{ this.delloading=false;},
    )

  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      brandName: [null, [Validators.required]],
      note: [null],
    });
  }

}
