import { Component, OnInit,OnChanges, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';
import {GlassesInfoService} from '@core/public/glasses-info.service';
import { Router, NavigationEnd, } from '@angular/router';

@Component({
  selector: 'app-choose-glasses-modal',
  templateUrl: './choose-glasses-modal.component.html',
  styleUrls: ['./choose-glasses-modal.component.css']
})
export class ChooseGlassesModalComponent implements OnInit {

  constructor(
    private router:Router,
    private glassesInfoSrv:GlassesInfoService,
  ) { 
    this.router.events
    .subscribe((event: NavigationEnd) => {
      if(event instanceof NavigationEnd){
        this.isVisible=false;
      }
    });

  }
  @Output() handleResult:EventEmitter<boolean> = new EventEmitter()
  @Input() isVisible:boolean=false;
  @Input() glassesType:string;
  closeModal():void{
    this.isVisible=false;
    this.handleResult.emit(this.isVisible)
  }

  /**跳转 */
  goLink(gender):void{
    this.closeModal();
    switch(this.glassesType){
      case 'sunglasses':
        this.router.navigate(['/sun-glasses'],{ queryParams: { gender: gender }});
        break;
      case 'eyeglasses':
        this.router.navigate(['/eye-glasses'],{ queryParams: { gender: gender }}); 
        break;
       default: 
       this.router.navigate(['/eye-glasses'],{ queryParams: { gender: gender }}); 
       break
    }
    // 发送消息
    let postGender={gender:gender,glassesType:this.glassesType?this.glassesType:'eyeglasses'}
    this.glassesInfoSrv.sendGlassesInfo(postGender);
  }

  ngOnInit() {
    // document.documentElement.style.overflow='hidden';
  }
 
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // console.log(changes)
    if(changes.isVisible.currentValue){
      document.documentElement.style.overflow='hidden';
    }else{
      document.documentElement.style.overflow='auto';
    }
  
    
  }

}
