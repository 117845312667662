import { Injectable } from '@angular/core';
/*
存储localstorage的键值对
*/
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() { }
  private ms = 'luxin';
  private storage = window.localStorage;
  private init(): any {
    localStorage.setItem(this.ms, '{"data":{}}');
  }
  public set(key, value): boolean {
    //存储
    let mydata = this.storage.getItem(this.ms);
    if (!mydata) {
      this.init();
      mydata = this.storage.getItem(this.ms);
    }
    mydata = JSON.parse(mydata);
    mydata['data'][key] = value;
    this.storage.setItem(this.ms, JSON.stringify(mydata));
    return mydata['data'];
  }
  public get(key): any {
    //读取
    let mydata = this.storage.getItem(this.ms);
    if (!mydata) {
      return false;
    }
    mydata = JSON.parse(mydata);

    return mydata['data'][key];
  }

  public remove(key): boolean {
    //读取
    let mydata = this.storage.getItem(this.ms);
    if (!mydata) {
      return false;
    }
    mydata = JSON.parse(mydata);
    delete mydata['data'][key];
    this.storage.setItem(this.ms, JSON.stringify(mydata));
    return mydata['data'];
  }

  public clear(): void {
    //清除对象
    this.storage.removeItem(this.ms);
  }
}
