
/*
 * @Description: 业务请求
 * @Author: cy
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  constructor(private http: HttpClient) {}
  
  getBrandList(data?: any): Observable<any> {
    return this.http.post<any>(`Folder/brandFolderList`, data);
  }
  
  getBrandDetail(param?: any): Observable<any> {
    return this.http.post<any>('Order/myOrderDetail', param)
  }
  addBrand(data?: any): Observable<any> {
    return this.http.post<any>('Folder/addBrandFolder', data)
  }
  /**修改品牌目录*/
  editBrandFolder(data?: any): Observable<any> {
    return this.http.post<any>('Folder/editBrandFolder', data)
  }
}
