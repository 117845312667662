import { Component, OnInit, ViewChild } from '@angular/core';
import {GlassesService} from '@core/httpservice/glasses.service';
import{ActivatedRoute} from '@angular/router';
import { FavoriteService} from '@core/httpservice/favorite.service';
import { StorageService} from '@core/public/storage.service';
import { NzMessageService } from 'ng-zorro-antd';
import {environment} from '@env/environment';
import { UserInfoService} from '@core/public/user-info.service';


@Component({
  selector: 'app-glasses-detail',
  templateUrl: './glasses-detail.component.html',
  styleUrls: ['./glasses-detail.component.css'],
  styles:[`
  [nz-carousel-content] {
    text-align: center;
    height: 600px;
    overflow: hidden;
  }
  `]
})
export class GlassesDetailComponent implements OnInit {

  constructor(
    private glassesService:GlassesService, 
    private route: ActivatedRoute,
    private favoriteService: FavoriteService, 
    private storageService: StorageService,
    private msg:NzMessageService,
    private userInfoSrv:UserInfoService,
    ) { }
  array=[1,2,3]
  fileUrl=environment.FILE_URL;

  @ViewChild('addMyOrderModal') addMyOrderModal;
  addMyOrderBtn():void{
    if(this.storageService.get('userToken')){
      this.addMyOrderModal.isVisible=true;
    }else{
      this.msg.info('Please log in first!')

    }
    
  }
  detailId: any='';
  detailData:Object={}


  getDetail () {
    let data={
      id:  this.detailId,
    }
    if(this.storageService.get('userToken')){
      data['user_token']=this.storageService.get('userToken')
    }
    this.glassesService.getClassesDetail(data).subscribe(
      res=>{
        if(res.code=="0"){
          this.detailData = res.data;
          this.colorList=this.detailData['colors'];
          this.updateColorList();
          this.isHert=res.data['detail']['isFavorite'];
          this.updateImage(res.data['detail']['product_id'],res.data['detail']['pictures']);
        }
       
      }
    )
  }

  // region 选择颜色
  colorList:any[]=[];
  colorId="";
  selectedColor(id):void{
    this.colorId=id;
    for(let key in this.colorCache){
      this.colorCache[key]['checked']=false;
    }
    this.colorCache[id]['checked']=true;
    this.updateImage(this.colorCache[id]['product_id'],this.colorCache[id]['pictures'])
  }
  // 存储颜色
  colorCache={};
  updateColorList():void{
    this.colorCache = new Object();
    this.colorList.forEach(item => {
      if (!this.colorCache[item.id]) {
        this.colorCache[item.id] = {
          checked: false,
          ...item,
        };
      }
    });
    this.colorCache[this.detailId]['checked']=true;
    this.colorId=this.detailId;
  }
  
  // endregion

  // region 添加收藏和取消收藏
  isHert:boolean=false;
  addFavoritesBtn():void{
    if(this.storageService.get('userToken')){
      let data={
        user_token:this.storageService.get('userToken'),
        id:this.colorId
      }
      this.favoriteService.addFavorites(data).subscribe(
        res=>{
          if(res.code=="0"){
            this.isHert=true;
            this.detailData['detail']['star_count']+=1;
            this.userInfoSrv.updataUserInfo();
          }
        }
      )
    }else{
      this.msg.info('Please log in first!')

    }
  }

  cancleFavoritesBtn():void{
    let data={
      user_token:this.storageService.get('userToken'),
      id:this.colorId
    }
    this.favoriteService.cancelFavorites(data).subscribe(
      res=>{
        if(res.code=="0"){
          this.isHert=false;
          if(this.detailData['detail']['star_count']>0){
            this.detailData['detail']['star_count']-=1;
            this.userInfoSrv.updataUserInfo();
          }
         
        }
      }
    )
  }
  //endregion

  // 处理图片
  /**展示的图片 */
  imageList:any[]=[];
  updateImage(productId,pictures:number):void{
    // 格式 /zip/55578-BL-A.jpg
    let imgUrlList=[];
    let arr=['A','B','C','D','E','F','G','H','I','J','K','L','M','N'].slice(0,pictures);
    arr.forEach(item=>{
      let url=`/zip/${productId}-${item}.jpg`;
      imgUrlList.push(url);
    })
    this.imageList=[...imgUrlList];

  }

  ngOnInit(): void {
    this.detailId = this.route.snapshot.queryParams['id']
    // console.log(this.detailId)
    this.getDetail()
  }

}
