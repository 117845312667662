
/*
 * @Description: 登录服务
 * @Author: cy
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PassportService {
  constructor(private http: HttpClient) {}
  /**
   * @msg: 注册*/
  register(data?: any): Observable<any> {
    return this.http.post<any>(`login/register`, data);
  }
  
 
  /**
   * @msg: 登陆*/
  login(data?: any): Observable<any> {
    return this.http.post<any>(`login/login`, data);
  }
  /**
   * @msg: 重置密码发送邮件
*/
  resetPassword(data?: any): Observable<any> {
    return this.http.post<any>(`Common/resetPassword`, data);
  }
  /**
   * @msg: 确认重置密码
*/
confirmResetPassword(data?: any): Observable<any> {
    return this.http.post<any>(`Common/confirmResetPassword`, data);
  }
  
}
