
/*
 * @Description: 业务请求
 * @Author: cy
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlassesService {
  constructor(private http: HttpClient) {}
  
  /*** @msg: 眼睛列表*/
  glassesList(data?: any): Observable<any> {
    return this.http.post<any>(`Glasses/glassesList`, data);
  }
  /**获取眼镜详情 */
  getClassesDetail(param?: any): Observable<any> {
    return this.http.post<any>('Glasses/glassesInfo', param)
  }
  /**获取眼镜属性 */
  getGlassesAttribute(param?: any): Observable<any> {
    return this.http.post<any>('Glasses/getGlassesAttribute', param)
  }
  /**我的收藏眼镜属性 */
  myFavoriteAttribute(param?: any): Observable<any> {
    return this.http.post<any>('Favorite/myFavoriteAttribute', param)
  }
  /**获取 new pick （列表页） */
  getNewGlasses(param?: any): Observable<any> {
    return this.http.post<any>('Glasses/getNewGlasses', param)
  }
/** 获取window */
  getNativeWindow() {
    return window;
}

}
