
/*
 * @Description: 业务请求
 * @Author: cy
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as $ from 'jquery';
import {environment} from '@env/environment';
import { StorageService} from '@core/public/storage.service';

@Injectable({
  providedIn: 'root',
})

	
export class ThemeService {
  constructor(private http: HttpClient) {}
  
  getThemeList(data?: any): Observable<any> {
    return this.http.post<any>(`Theme/themeList`, data);
  }
  getThemeOne(data?: any): Observable<any> {
    return this.http.post<any>(`Theme/themeOne`, data);
  }
  addMyThemeInfo(data?: any): Observable<any> {
    return this.http.post<any>(`Themeinfo/addMyThemeInfo`, data);
  }
  addThemeInfoThumbUp(data?: any): Observable<any> {
    return this.http.post<any>(`Themeinfo/addThemeInfoThumbUp`, data);
  }
 
 getmyTheme(data?: any): Observable<any> {
    return this.http.post<any>(`Theme/myTheme`, data);
  }
 
  download(data): Observable<any> {
    return this.http.post<any>(`Theme/myDownload`, data);
  }
   getNativeWindow() {
    return window;
}
  
  public uploadImg(fileList: Array<any>): any {
    let formData = new FormData();
    formData.append('file', fileList[0]);
    // formData.append('user_token', this.storageService.get('userToken'));
    return new Promise((res, rej) => {
      $.ajax({
        url: `${environment.SERVER_URL}common/imgUpload`,
        type: 'POST',
        data: formData,
        async: false,
        cache: false,
        processData: false,
        contentType: false,
        dataType: 'json',
        success: function(data) {
          if (data.code == '0') {
            res(data);
          } else {
            rej(false);
          }
        },
        error: function() {
        
          rej(false);
        },
      });
    });
  }
  
}
