import { Component, OnInit } from '@angular/core';
import { UserService} from '@core/httpservice/user.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  constructor(private userSrv:UserService) { }
  
  cantactInfo:any;
  /**关于我们 */
  aboutUs:object;
  /**联系方式 */
  contacts:Object={};

  /**获取联系我们 */
  getCantactInfo():void{
    this.userSrv.getConfigList().subscribe(
      res=>{
        if(res.code=='0'){
          // this.aboutUs=res.data.data.find(item=>item['name']=='web_about_us');
          // let web_office_address=res.data.data.find(item=>item['name']=='web_office_address');
          // let web_email=res.data.data.find(item=>item['name']=='web_email');
          // let web_tel=res.data.data.find(item=>item['name']=='web_tel');
          // let web_fax=res.data.data.find(item=>item['name']=='web_fax');
          // this.contacts={web_office_address,web_email,web_tel,web_fax}
         
          res.data.data.forEach(item => {
            this.contacts[item.name]=item;
          });
          // console.log(this.contacts)
        }
      }
    )
  }

  ngOnInit() {
    this.getCantactInfo();
  }

}
